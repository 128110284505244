import React from 'react';
import "./../stylesheets/components/cmp-modal.sass"

export const CMPModalManager = {};

class CMPModal extends React.Component {

	constructor(props) {
		super(props);

		this.state = { visible: false };

		this.close = this.close.bind(this);
		this.open = this.open.bind(this);

		this.handleWindowClick = this.handleWindowClick.bind(this);
		this.handleKeyup = this.handleKeyup.bind(this);

		CMPModalManager.Open = this.open;
		CMPModalManager.Close = this.close;
	}

	handleWindowClick(e) {
		this.close(e);
	}

	handleKeyup(e) {
		if (e.code === "Escape") {
			this.setState({ visible: false });
		}
	}

	close(e) {
		this.setState({ visible: false });
	}

	open(e) {
		e.preventDefault();
		e.stopPropagation();
		this.setState({ visible: true });
	}

	render() {

		const classList = {
			root: 'cmp-modal',
			visible: this.state.visible ? 'cmp-modal--visible' : null,
		};

		return (
			<div className={Object.values(classList).map((style) => style).join(' ')}>
				<div className="cmp-modal__overlay">
					<div className="cmp-modal__content">
						{this.props.title ?
							(
								<header>
									<h2 className="cmp-modal__title">{this.props.title}</h2>
								</header>
							) : null
						}
						<article className="cmp-modal__main">
							{this.props.children}
						</article>
						<p className="cmp-modal__dismiss">&ndash; Click anywhere or press ESC to close &ndash;</p>
					</div>
				</div>
			</div>
		);
	}

	componentDidMount() {
		window.addEventListener('click', this.handleWindowClick, false);
		window.addEventListener('keyup', this.handleKeyup, false);
	}

	componentWillUnmount() {
		window.removeEventListener('click', this.handleWindowClick, false);
		window.removeEventListener('keyup', this.handleKeyup, false);
	}
}

export default CMPModal;
