import React from 'react';
import BSNNavigationPanel from './../components/business/bsn-navigation-panel';
import BSNSEOBanner from './../components/business/bsn-seo-banner';
import BSNHeader from './../components/business/bsn-header';
import BSNFooter from './../components/business/bsn-footer';
import "./../stylesheets/pages/home.sass";

class Home extends React.Component {

	render() {
		return (
			<div id="home">

				<BSNHeader>
					<BSNNavigationPanel language={this.props.lang} active="home" activeLanguage="EN" />
					<BSNSEOBanner />
				</BSNHeader>

				<BSNFooter />

			</div>
		);
	}
}

export default Home;
