export default {
  identity: {
    legalName: 'Kouzoglou Aristeidis PLC',
    address: 'L. Dimokratias 28, Perama',
    postalCode: '18863',
    country: 'Greece',
    email: 'info@kouzoglouinsulations.gr',
    phone: '(+30)6974796498',
    phone1: 'Aris: (+30)6974796498', // Aris
    phone2: 'Panagiotis: (+30)6932814628', // Panagiotis
  },
  terms: {
    lastModified: '10 Feb 2021',
  },
  language: {
    en: {
      abbr: 'ENG',
    },
    el: {
      abbr: 'EL',
    },
  },
  menu: {
    handler: 'MENU',
    home: 'Home',
    about: 'About',
    contact: 'Contact',
    gallery: 'Gallery',
    services: 'Services',
    clients: 'Clients',
    unsubscribe: 'Unsubscribe',
  },
  forms: {
    generic: {
      validation: {
        chars: 'characters',
        required: 'Field cannot be empty',
        email: 'Email is not in the correct format',
        maxChars: 'Enter a maximum of',
        minChars: 'Enter at least',
        exactChars: 'Enter exaclty',
      },
      unavailable: {
        message:
          'The form is out of order. Please contact the system administrator',
      },
    },
    optOut: {
      fields: {
        email: {
          title: 'Email',
          placeholder: 'info@example.com',
        },
        submit: 'Unsubscribe',
      },
    },
  },
  components: {
    cmpMap: {
      fullMap: 'See larger map',
    },
  },
  pages: {
    home: {
      seo: {
        title: 'Ship & Yacht insulation services',
        description:
          'Our company is active in the field of insulations in ships, yachts and land facilities since 1989. Our expertise in the field, combined with top-quality materials, enable us to undertake every request. Our services are delivered 24/7 inside and outside of Greece.',
        keypoints: {
          one: 'Quick response',
          two: 'Quality services',
          three: 'After service support',
        },
        cta1: 'Our services',
        cta2: 'Gallery',
      },
    },
    services: {
      section: {
        one: {
          title: 'Lamination',
          description:
            'We specialize in laminations and floor arrangments using certified two-component (polyurethanes, epoxy) Marine materials.',
        },
        two: {
          title: 'Waterproofing',
          description:
            'We undertake any waterproofing task using polyurethanes sealant materials and make sure for their excellent application.',
        },
        three: {
          title: 'Thermal Insulation',
          description:
            'Thermal insulation can be applied to any indoor space using certified varied density thermal insulation components, according to the regulations.',
        },
        four: {
          title: 'Soundproofing',
          description:
            'We apply any soundproofing project, in any given space, using soundproofing materials and according to the established specifications on sound reduction.',
        },
        five: {
          title: 'Fireproofing',
          description:
            'We undertake any fireproofing task. All applications are in compliance with nautical regulations and we use only cerfified materials.',
        },
        six: {
          title: 'Thermal Insulation Covers',
          description:
            'We build and place customized thermal insulation covers for main and electric engines, using certified materials that are heat-resistant between 1260°C and 1400°C.',
        },
      },
      categories: 'Categories',
      all: 'All',
      lamination: 'Lamination',
      waterproofing: 'Waterproofing',
      thermalInsulation: 'Thermal Insulation',
      soundproofing: 'Soundproofing',
      fireproofing: 'Fireproofing',
      thermalInsulationCovers: 'Thermal Insulation Covers',
    },
    gallery: {
      section: {
        one: {
          title: 'Works and installations',
          description:
            '█████████████ ███████ ████ █████████ ██████████████████████ ███ ████████████ ████████████████████ █████████ ████████████████████████ ███ ███ ██████████ ███████████ ████████████ ████████████████████ ████████████████████ █ ███████████ ██████████ ████████████ █████████',
        },
      },
    },
    clients: {
      section: {
        one: {
          title: 'Clients',
          description:
            '█████ ██████████████ ██████████████████ ██████████ ██████ ██████████ ██████ ████████████████████ █████ ███████████████ ████ █████████████ ███████ ███████',
        },
      },
      top: {
        title: 'Trusted by',
      },
      all: {
        title: 'All companies',
      },
      showAllButton: 'Show all',
    },
    about: {
      section: {
        one: {
          title: 'Who we are',
          description:
            '█ █████ ███████ █████████ ███████ █████ █████████████ █ ██████ █████████████ ██████ ████████ █████████████ █████ ████ ██████████████ ████ ███ ████████████ ██ ███████ ████████ ███ ████ ██████████ ███ ██████████ ██ █████████ ███ ██ ███ ██',
          caption: 'Our company headquarters along with our vehicles',
        },
        two: {
          title: 'Our story',
          description:
            '██████ ██████ ███ ██████ ███████ ██ ███ ███████████ ███████ ███ ████████ ███ █ █████████ █████ ██ ████ ██████████ ████ █████ ████████████ ██ ████ ██████ ████ █ █████████ ██████ ██ ███████ ███████ █████████ ██████ ███████████ █ ███████ ██████ ███',
        },
      },
    },
    contact: {
      info: {
        title: 'Contact info',
        description:
          'You can use the form below to ask us anything you want. below to ask us anything you want.',
      },
      form: {
        title: 'Contact form',
        description:
          'You can use the form below to ask us anything you want. Please feel free to contact us about anything. We will be glad to get in touch with you.',
        unavailable: {
          message:
            'The form is out of order. Please contact the system administrator',
        },
        fields: {
          name: {
            title: 'Full Name',
            placeholder: 'Enter your name',
            validation: {
              required: 'Enter your name',
            },
          },
          phone: {
            title: 'Phone',
            placeholder: 'e.g. 6999999999',
            validation: {
              required: 'Enter a contact phone',
            },
          },
          email: {
            title: 'Email',
            placeholder: 'info@example.com',
            validation: {
              required: 'Enter your email',
              email: 'Email is not in the correct format',
            },
          },
          subject: {
            title: 'Subject',
            options: {
              default: 'Select subject...',
              question: 'Question',
              clarification: 'Clarification',
              complaint: 'Complaint',
              proposal: 'Proposal',
              other: 'Other',
            },
            validation: {
              required: 'Select a subject',
            },
          },
          message: {
            title: 'Message',
            placeholder: 'Write your message here',
            validation: {
              required: 'Enter your message',
            },
          },
          gdpr: {
            title: 'I accept the ',
            terms: 'privacy terms and contact regulations (GDPR)',
            validation: {
              required: 'Please accept our terms and conditions',
            },
          },
          submit: {
            title: 'Submit',
          },
        },
      },
    },
    unsubscribe: {
      message:
        'By entering your email you are opting-out from our very limited newslist. Your choice will be respected and your name, email and phone will be removed from our database. Please consider staying.',
      backToHome: 'Back to home',
    },
    404: {
      title: 'Page not found',
      action: 'Back to home',
    },
    emailMessage: {
      moto: 'Insulation solutions for ships and yatchs',
      name: 'Name',
      phone: 'Phone',
      email: 'Email',
      message: 'Message',
      thankYouTitle: 'Contact',
      thankYouForContactingUs: 'Thank you for reaching out',
      messageReceived: 'We received your message and we will process it asap',
      cantSeeThisMessage: "If you can't read this message",
      viewItInBrowser: 'open&nbsp;it&nbsp;in&nbsp;the&nbsp;browser',
      messageSent: 'Your original message',
      yourContactDetails: 'Your contact details',
      thankYouMessage:
        'Thank you for reaching out. We will process your message as soon as possible.',
    },
  },
  form: {
    contact: {
      error: {
        network:
          "An error occured while your message was being sent. Please try again and should the problem persists, don't hesitate to drop us an email at",
        validation:
          "Please check the following issues and re-enter the data to the neccessary fields according to the hints below. If the problem persists, don't hesitate to drop us an email at",
      },
      success:
        'Your message is submitted successfully. We will process your message asap and, if needed, we will get back to you. In a few minutes you will receive a copy of the message in your inbox.',
    },
  },
  cmpGallery: {
    emptyState: 'No images :(',
  },
  api: {
    USER_NOT_AUTHORIZED: {
      code: 'E1001',
      message: "An error occured during the form's initialization",
    },
    SERVICES_UNAVAILABLE: {
      code: 'E1002',
      message: 'An error occured while trying to connect to the API',
    },
    SUBMIT_SUCCESS: {
      code: 'S2010',
      message: 'Message sent successfully',
    },
    SUBMIT_FAIL: {
      code: 'E2010',
      message: 'Message could not be sent',
    },
    OPTOUT_SUCCESS: {
      code: 'S2020',
      message: 'Successfully unsubscribed',
    },
    OPTOUT_FAIL: {
      code: 'E1020',
      message: 'Could not remove from subscriptions list',
    },
  },
};
