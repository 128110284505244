import React from 'react';
import Translate from 'counterpart';
import CMPError from './../components/cmp-error';
import CMPLogo from './../components/cmp-logo';
import CMPLink from './../components/cmp-link';
import CMPCopyright from './../components/cmp-copyright';

import "./../stylesheets/pages/404.sass";

class E404 extends React.Component {

	render() {

		return (
			<div id="e-404">

				<CMPLogo imagePath="/images/logo.png" />

				<CMPError 
					code="404" 
					title={Translate("pages.404.title")}
					action={<CMPLink to="/" text={Translate("pages.404.action")} />}
				/>

				<CMPCopyright />
			</div>
		);
	}

}

export default E404;