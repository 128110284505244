import React from 'react';
import { Link } from 'react-router-dom';

import "./../stylesheets/components/cmp-link.sass"

class CMPLink extends React.Component {

	render() {

		const classList = {
			root: 'cmp-link'
		};

		if (this.props.to) {
			return (
				<Link className={Object.values(classList).map((style) => style).join(' ')} to={this.props.to}>
					{this.props.text}
				</Link>
			)
		}

		else if (this.props.url) {
			return (
				<a className={Object.values(classList).map((style) => style).join(' ')} href={this.props.url}>
					{this.props.text}
				</a>
			)
		}

		else if (this.props.action) {
			return (
				<span className={Object.values(classList).map((style) => style).join(' ')} onClick={this.props.action}>
					{this.props.text}
				</span>
			)
		}
	}
};

export default CMPLink;
