import React from 'react';
import BSNContactInformation from './bsn-contact-information';
import CMPCopyright from './../cmp-copyright';
import "./../../stylesheets/components/business/bsn-footer.sass"

class BSNFooter extends React.Component {

	render() {

		return (
			<footer className="bsn-footer">
				<BSNContactInformation />
				<CMPCopyright />
			</footer>
		);
	}
}

export default BSNFooter;
