import { Keys, GetStoredObject } from './storage';
import { Reply } from './helpers';
import { Notify as MDCSnackbarNotify } from './../components/material/mdc-snackbar';
import Config from './../config';

const endpoints = {
	check: () => {
		return {
			url: `${Config.API.url}/secure`,
			method: 'GET'
		}
	},
	messages: {
		create: () => {
			return {
				url: `${Config.API.url}/messages`,
				method: 'POST'
			}
		},
		get: (id) => {
			return {
				url: `${Config.API.url}/messages/${id}`,
				method: 'GET'
			}
		}
	},
	unsubsribes: {
		create: () => {
			return {
				url: `${Config.API.url}/unsubscribes`,
				method: 'POST'
			}
		},
	},
	users: {
		authorize: () => {
			return {
				url: `${Config.API.url}/users/authorize`,
				method: 'POST'
			}
		},
		create: () => {
			return {
				url: `${Config.API.url}/users`,
				method: 'POST'
			}
		}
	}
};

const request = async (endpoint, data) => {
	// Setup request options (configuration)
	const options = {
		method: endpoint.method,
		mode: 'cors',
		headers: {
			'Content-Type': 'application/json',
			'Authorization': `Bearer ${GetStoredObject(Keys.USER_TOKEN)}`
		}
	};

	// If data are passed
	if (data)
		options.body = JSON.stringify(data);

	// Send request
	try {
		return (await fetch(endpoint.url, options)).json();
	}
	catch (err) {
		const error = `${err.message} - ${endpoint.url}`;
		MDCSnackbarNotify(error, { buttonText: 'DISMISS', status: 'error' });
		return Reply.basic({ message: error, errors: [error] });
	}
};

const response = (response) => {
	// Handle response
	if (response.errors && response.errors.length > 0)
		return Reply.basic({ message: response.message, errors: response.errors });
	else
		return Reply.basic({ data: response, message: response.message, success: true });
}

const API = {
	endpoints,
	request,
	response,
}

export default API;
