export default {
  identity: {
    legalName: 'Κουζόγλου Αριστείδης Μον/πη ΙΚΕ',
    address: 'Λ. Δημοκρατίας 28, Πέραμα',
    postalCode: '18863',
    country: 'Ελλάδα',
    email: 'info@kouzoglouinsulations.gr',
    phone: '(+30)6974796498',
    phone1: 'Άρης: (+30)6974796498', // Aris
    phone2: 'Παναγιώτης: (+30)6932814628', // Panagiotis
  },
  terms: {
    lastModified: '10 Φεβ 2021',
  },
  language: {
    en: {
      abbr: 'ENG',
    },
    el: {
      abbr: 'EL',
    },
  },
  menu: {
    handler: 'ΜΕΝΟΥ',
    home: 'Αρχικη',
    about: 'Σχετικα',
    contact: 'Επικοινωνια',
    gallery: 'Εργα',
    services: 'Υπηρεσιες',
    clients: 'Πελατολογιο',
    unsubscribe: 'Διαγραφη Συνδρομης',
  },
  forms: {
    generic: {
      validation: {
        chars: 'χαρακτήρες',
        required: 'Συμπληρώστε το πεδίο',
        email: 'Το email δεν είναι σε αποδεκτή μορφή',
        maxChars: 'Εισάγετε μέχρι',
        minChars: 'Εισάγετε τουλάχιστον',
        exactChars: 'Εισάγετε ακριβώς',
      },
      unavailable: {
        message: 'Η φόρμα είναι εκτός λειτουργίας. Παρακαλούμε ενημερώστε μας',
      },
    },
    optOut: {
      fields: {
        email: {
          title: 'Email',
          placeholder: 'info@example.com',
        },
        submit: 'Διαγραφη',
      },
    },
  },
  components: {
    cmpMap: {
      fullMap: 'Δείτε μεγαλύτερο χάρτη',
    },
  },
  pages: {
    home: {
      seo: {
        title: 'Μονωσεις και επιδιορθωσεις σε πλοια και γιοτ',
        description:
          'Η εταιρεία μας δραστηριοποιείται στον χώρο των μονώσεων σε πλοία yacht και χερσαίες εγκαταστάσεις από το 1989. Με γνώμονα πάντα την άριστη τεχνογνωσία και την ποιότητα των υλικών φέρνουμε εις πέρας κάθε είδους απαίτηση του πελάτη. Η εταιρεία προσφέρει τις υπηρεσίες της όλο το 24ωρο εντός και εκτος Ελλάδος.',
        cta1: 'Τι προσφερουμε',
        cta2: 'Οι δουλειες μας',
      },
    },
    services: {
      section: {
        one: {
          title: 'Πλαστικοποιηση',
          description:
            'Εξειδικευόμαστε σε πλαστικοποιήσεις και διαμορφώσεις δαπέδων με υλικά Marine πιστοποιημένα, δύο συστατικών (πολυουρεθανικά / εποξειδικά).',
        },
        two: {
          title: 'Στεγανοποιηση',
          description:
            'Αναλαμβάνουμε παντός είδους στεγανοποιήσεις με πιστοποιημένα σφραγιστικά πολυουρεθανικά υλικά και φροντίζουμε για την άριστη εφαρμογή τους.',
        },
        three: {
          title: 'Θερμομονωση',
          description:
            'Η τοποθέτηση θερμομόνωσης αφορά εσωτερικούς χώρους (παντός είδους), με πιστοποιημένα υλικά καυστότητας διαφορετικής πυκνότητας βάση των κανονισμών.',
        },
        four: {
          title: 'Ηχομονωση',
          description:
            'Αναλαμβάνουμε κάθε είδους ηχομόνωση σε οποιοδήποτε χώρο απαιτείται, με ηχοαπορροφητικά υλικά και τις ανάλογες προδιαγραφές για την μείωση των ήχων.',
        },
        five: {
          title: 'Πυρασφαλεια',
          description:
            'Εξειδικευόμαστε σε κάθε είδους πυρασφάλεια και η εφαρμογή της γίνεται βάση των κανονισμών ναυτιλίας, με υλικά που φέρουν τα ανάλογα πιστοποιητικά.',
        },
        six: {
          title: 'Θερμομονωτικα Καλυμματα',
          description:
            'Αναλαμβάνουμε κάθε είδους κατασκευή και τοποθέτηση θερμομονωτικών καλυμμάτων σε οχετούς κυρίων μηχανών και ηλεκτρομηχανών με πιστοποιημένα υλικά καυστότητας για βαθμούς από 1260°C έως 1400°C.',
        },
      },
      categories: 'Κατηγοριες',
      all: 'Ολα',
      lamination: 'Πλαστικοποιηση',
      waterproofing: 'Στεγανοποιηση',
      thermalInsulation: 'Θερμομονωση',
      soundproofing: 'Ηχομονωση',
      fireproofing: 'Πυρασφαλεια',
      thermalInsulationCovers: 'Θερμομονωτικα Καλυμματα',
    },
    gallery: {
      section: {
        one: {
          title: 'Οι δουλειες μας',
          description:
            '█████████████ ███████ ████ █████████ ██████████████████████ ███ ████████████ ████████████████████ █████████ ████████████████████████ ███ ███ ██████████ ███████████ ████████████ ████████████████████ ████████████████████ █ ███████████ ██████████ ████████████ █████████',
        },
      },
    },
    clients: {
      section: {
        one: {
          title: 'Πελατες',
          description:
            '█████ ██████████████ ██████████████████ ██████████ ██████ ██████████ ██████ ████████████████████ █████ ███████████████ ████ █████████████ ███████ ███████',
        },
      },
      top: {
        title: 'Μας εμπιστευονται',
      },
      all: {
        title: 'Ολες οι εταιρειες',
      },
      showAllButton: 'Εμφανιση ολων',
    },
    about: {
      section: {
        one: {
          title: 'Ποιοι ειμαστε',
          description:
            '█ █████ ███████ █████████ ███████ █████ █████████████ █ ██████ █████████████ ██████ ████████ █████████████ █████ ████ ██████████████ ████ ███ ████████████ ██ ███████ ████████ ███ ████ ██████████ ███ ██████████ ██ █████████ ███ ██ ███ ██',
          caption: 'Η έδρα της εταιρείας και τα επαγγελματικά μας οχήματα',
        },
        two: {
          title: 'Η ιστορια μας',
          description:
            '██████ ██████ ███ ██████ ███████ ██ ███ ███████████ ███████ ███ ████████ ███ █ █████████ █████ ██ ████ ██████████ ████ █████ ████████████ ██ ████ ██████ ████ █ █████████ ██████ ██ ███████ ███████ █████████ ██████ ███████████ █ ███████ ██████ ███',
        },
      },
    },
    contact: {
      info: {
        title: 'Στοιχεια επικοινωνιας',
        description: 'Βρείτε μας, με όποιον τρόπο προτιμάτε',
      },
      form: {
        title: 'Φορμα επικοινωνιας',
        description: 'Στείλτε το μήνυμα σας, και θα χαρούμε να σας απαντήσουμε',
        unavailable: {
          message:
            'Η φόρμα είναι εκτός λειτουργίας. Παρακαλούμε ενημερώστε μας',
        },
        fields: {
          name: {
            title: 'Ονοματεπωνυμο',
            placeholder: 'Συμπληρώστε το όνομα σας',
            validation: {
              required: 'Εισάγετε το όνομα σας',
            },
          },
          phone: {
            title: 'Τηλεφωνο',
            placeholder: 'π.χ. 6999999999',
            validation: {
              required: 'Εισάγετε ένα τηλέφωνο επικοινωνίας',
            },
          },
          email: {
            title: 'Email',
            placeholder: 'info@example.com',
            validation: {
              required: 'Εισάγετε ένα email επικοινωνίας',
              email: 'Το email δεν είναι σε αποδεκτή μορφή',
            },
          },
          subject: {
            title: 'Θεμα',
            options: {
              default: 'Επιλέξτε θέμα...',
              question: 'Ερώτηση',
              clarification: 'Διευκρίνηση',
              complaint: 'Παράπονο',
              proposal: 'Πρόταση',
              other: 'Άλλο',
            },
            validation: {
              required: 'Επιλέξτε ένα θέμα',
            },
          },
          message: {
            title: 'Μηνυμα',
            placeholder: 'Γράψτε το μήνυμά σας εδώ',
            validation: {
              required: 'Εισάγετε το μήνυμα σας',
            },
          },
          gdpr: {
            title: 'Αποδεχομαι τους ',
            terms: 'ορους επικοινωνιας και απορρητου (GDPR)',
            validation: {
              required: 'Παρακαλούμε αποδεχτείτε τους όρους χρήσης',
            },
          },
          submit: {
            title: 'Αποστολη',
          },
        },
      },
    },
    unsubscribe: {
      message:
        'Συμπληρώνοντας το email σας παρακάτω, συμφωνείτε να διαγραφείτε από την, αρκετά περιορισμένη, λίστα επικοινωνίας μας. Η επιλογή σας θα γίνει σεβαστή και τα στοιχεία σας (όνομα, email, τηλέφωνο) θα διαγραφούν οριστικά από τη βάση δεδομένων. Θα σας παρακαλούσαμε να το ξανασκεφτείτε.',
      backToHome: 'Επιστροφή στην αρχική',
    },
    404: {
      title: 'Η σελιδα δεν βρεθηκε',
      action: 'Επιστροφή στην αρχική',
    },
    emailMessage: {
      moto: 'Μονώσεις και επιδιορθώσεις σε πλοία και γιότ',
      name: 'Όνομα',
      phone: 'Τηλέφωνο',
      email: 'Email',
      message: 'Μήνυμα',
      thankYouTitle: 'Επικοινωνία',
      thankYouForContactingUs: 'Ευχαριστούμε που επικοινωνήσατε μαζί μας',
      messageReceived:
        'Λάβαμε το μήνυμα σας και θα το επεξεργαστούμε το συντομότερο δυνατό',
      cantSeeThisMessage: 'Αν δεν μπορείτε να δείτε αυτό το μήνυμα',
      viewItInBrowser: 'δείτε&nbsp;το&nbsp;στο&nbsp;browser',
      messageSent: 'Το μήνυμα που μας στείλατε',
      yourContactDetails: 'Τα στοιχεία επικοινωνίας σας',
      thankYouMessage:
        'Σας ευχαριστούμε που επικοινωνήσατε μαζί μας. Θα επεξεργαστούμε το μήνυμα σας το συντομότερο δυνατό.',
    },
  },
  form: {
    contact: {
      error: {
        network:
          'Παρουσιάστηκε πρόβλημα κατά την αποστολή των δεδομένων σας. Παρακαλώ δοκιμάστε ξανά και αν το πρόβλημα επιμένει, παρακαλούμε στείλτε μας ένα email στο',
        validation:
          'Παρακαλώ ελέγξτε τα παρακάτω προβλήματα και διορθώστε τα προβληματικά πεδία στη φόρμα. Αν το πρόβλημα επιμένει, παρακαλούμε στείλτε μας ένα email στο',
      },
      success:
        'Η υποβολή της φόρμας επικοινωνίας ολοκληρώθηκε με επιτυχία. Θα επεξεργαστούμε το μήνυμά σας και, εφόσον χρειάζεται, θα επικοινωνήσουμε μαζί σας το συντομότερο δυνατό! Σε λίγα λεπτά θα λάβετε και ένα αντίγραφο του μηνύματος σας στη διεύθυνση που δηλώσατε.',
    },
  },
  cmpGallery: {
    emptyState: 'Δεν υπάρχουν εικόνες :(',
  },
  api: {
    USER_NOT_AUTHORIZED: {
      code: 'E1001',
      message: 'Παρουσιάστηκα σφάλμα κατά την αρχικοποίηση της φόρμας',
    },
    SERVICES_UNAVAILABLE: {
      code: 'E1002',
      message: 'Παρουσιάστηκε σφάλμα κατά την σύνδεση με το API',
    },
    SUBMIT_SUCCESS: {
      code: 'S2010',
      message: 'Το μήνυμα εστάλη με επιτυχία',
    },
    SUBMIT_FAIL: {
      code: 'E2010',
      message: 'Αδυναμία αποστολής μηνύματος',
    },
    OPTOUT_SUCCESS: {
      code: 'S2020',
      message: 'Επιτυχής αφαίρεση από την λίστα',
    },
    OPTOUT_FAIL: {
      code: 'E1020',
      message: 'Αδυναμία αφαίρεσης από την λίστα',
    },
  },
};
