import React from 'react';
import Config from './../config'
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
import Translate from 'react-translate-component';
import CMPLogo from './cmp-logo';
import BSNContactInformation from './business/bsn-contact-information';
import "./../stylesheets/components/cmp-map.sass"

const designCoordinates = {
	lat: 37.965049,
	lng: 23.563018
}; // a little offset in order to appear nice in the design

const markerCoordinates = {
	lat: 37.963182,
	lng: 23.562764
};

const theme = [
		 	 	{
		 	 		"featureType": "all",
		 	 		"elementType": "labels.text.fill",
		 	 		"stylers": [
		 	 		{
		 	 			"color": "#ffffff"
		 	 		}
		 	 		]
		 	 	},
		 	 	{
		 	 		"featureType": "all",
		 	 		"elementType": "labels.text.stroke",
		 	 		"stylers": [
		 	 		{
		 	 			"visibility": "on"
		 	 		},
		 	 		{
		 	 			"color": "#3e606f"
		 	 		},
		 	 		{
		 	 			"weight": 2
		 	 		},
		 	 		{
		 	 			"gamma": 0.84
		 	 		}
		 	 		]
		 	 	},
		 	 	{
		 	 		"featureType": "all",
		 	 		"elementType": "labels.icon",
		 	 		"stylers": [
		 	 		{
		 	 			"visibility": "off"
		 	 		}
		 	 		]
		 	 	},
		 	 	{
		 	 		"featureType": "administrative",
		 	 		"elementType": "geometry",
		 	 		"stylers": [
		 	 		{
		 	 			"weight": 0.6
		 	 		},
		 	 		{
		 	 			"color": "#1a3541"
		 	 		}
		 	 		]
		 	 	},
		 	 	{
		 	 		"featureType": "landscape",
		 	 		"elementType": "geometry",
		 	 		"stylers": [
		 	 		{
		 	 			"color": "#2c5a71"
		 	 		}
		 	 		]
		 	 	},
		 	 	{
		 	 		"featureType": "poi",
		 	 		"elementType": "geometry",
		 	 		"stylers": [
		 	 		{
		 	 			"color": "#406d80"
		 	 		}
		 	 		]
		 	 	},
		 	 	{
		 	 		"featureType": "poi.park",
		 	 		"elementType": "geometry",
		 	 		"stylers": [
		 	 		{
		 	 			"color": "#2c5a71"
		 	 		}
		 	 		]
		 	 	},
		 	 	{
		 	 		"featureType": "road",
		 	 		"elementType": "geometry",
		 	 		"stylers": [
		 	 		{
		 	 			"color": "#29768a"
		 	 		},
		 	 		{
		 	 			"lightness": -37
		 	 		}
		 	 		]
		 	 	},
		 	 	{
		 	 		"featureType": "transit",
		 	 		"elementType": "geometry",
		 	 		"stylers": [
		 	 		{
		 	 			"color": "#406d80"
		 	 		}
		 	 		]
		 	 	},
		 	 	{
		 	 		"featureType": "water",
		 	 		"elementType": "geometry",
		 	 		"stylers": [
		 	 		{
		 	 			"color": "#193341"
		 	 		}
		 	 		]
		 	 	}
	 	 	];

export class CMPMap extends React.Component {

	constructor(props) {
		super(props);

		this.state = { activeMarker: {}, showingInfoWindow: false };
		this.onMarkerClick = this.onMarkerClick.bind(this);
		this.onMapClicked = this.onMapClicked.bind(this);

		this.preventRobotoFontLoading();
	}

	preventRobotoFontLoading() {
		var head = document.getElementsByTagName('head')[0];

		// Save the original method
		var insertBefore = head.insertBefore;

		// Replace it!
		head.insertBefore = function (newElement, referenceElement) {

		    if (newElement.href && newElement.href.indexOf('//fonts.googleapis.com/css?family=Roboto') > -1) {
		        // console.info('Prevented Roboto from loading!');
		        return;
		    }

		    insertBefore.call(head, newElement, referenceElement);
		};
	}

	onMarkerClick(props, marker, e) {
		this.setState({
				showingInfoWindow: true,
				activeMarker: marker,
		});
	}

	onMapClicked() {
		if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null
      })
    }
	}

	render() {
		return (
			<div className="cmp-map">
				<Map
					google={this.props.google}
					initialCenter={designCoordinates}
					zoom={15}
					styles={theme}
					style={{ width: '100%',  height: '600px'}}
					zoomControl={false}
		 	 		scaleControl={false}
		 	 		scrollwheel={false}
		 	 		streetViewControl={false}
		 	 		mapTypeControl={false}
		 	 		onClick={this.onMapClicked}>

					<Marker onClick={this.onMarkerClick} position={markerCoordinates} name={'Current location'} icon={{url: "/images/marker.png" }}/>

					<InfoWindow onClose={this.onInfoWindowClose} visible={this.state.showingInfoWindow} marker={this.state.activeMarker}>
				 	 	<div className="cmp-info-window">
				 	 		<main className="cmp-info-window__content">
						 	 	<CMPLogo imagePath="images/logo.png" />
						 	 	<BSNContactInformation />
					 	 	</main>
					 	 	<footer className="cmp-info-window__footer">
					 	 	<Translate component="a" className="cmp-info-window__link" href="https://goo.gl/maps/niMjojzy3Zp" target="_blank" content="components.cmpMap.fullMap" />
					 	 	</footer>
				 	 	</div>
					</InfoWindow>

				</Map>
			</div>
		);
	}
}

export default GoogleApiWrapper({
	apiKey: (Config.google.maps.key)
})(CMPMap)
