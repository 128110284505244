import React from 'react';
import Translate from 'counterpart';
import CMPLink from './../components/cmp-link';
import CMPLogo from './../components/cmp-logo';
import CMPHeader from './../components/cmp-header';
import CMPContent from './../components/cmp-content';
import CMPOptOutForm from './../components/cmp-opt-out-form';
import CMPCopyright from './../components/cmp-copyright';
import BSNHeader from './../components/business/bsn-header';
import BSNMain from './../components/business/bsn-main';
import "./../stylesheets/pages/unsubscribe.sass";

class Unsubscribe extends React.Component {

	render() {
		return (
			<div id="unsubscribe">

				<BSNHeader>
					<CMPLogo imagePath="images/logo.png" title="Kouzoglou Insulations" shortTitle="kouzoglou Insulations" />
					<CMPHeader title={Translate("menu.unsubscribe")} />
				</BSNHeader>

				<BSNMain>

					<CMPContent>
						<p>{Translate("pages.unsubscribe.message")}</p>
						<CMPOptOutForm />

						<div className="unsubscribe__back-to-home-link">
							<CMPLink to="/" text={Translate("pages.404.action")} />
						</div>

					</CMPContent>

				</BSNMain>
				<CMPCopyright />
			</div>
		);
	}
}

export default Unsubscribe;
