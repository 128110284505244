import React from 'react';
import Translate from 'counterpart';
import * as moment from 'moment';
import "./../stylesheets/components/cmp-copyright.sass"

class CMPCopyright extends React.Component {

	render() {
		return (
			<p className="cmp-copyright">
				<span>&copy; {moment().format("YYYY")} {Translate("identity.legalName")}</span>
			</p>
		);
	}
}

export default CMPCopyright;

