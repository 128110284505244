import React from 'react';
import Translate from 'counterpart';
import Config from './../../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneAlt, faEnvelope, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import "./../../stylesheets/components/business/bsn-contact-information.sass"

class BSNContactInformation extends React.Component {

	render() {

		return (
			<ul className="bsn-contact-information">
				<li className="bsn-contact-information__item bsn-contact-information__item--phone">
					<FontAwesomeIcon className="bsn-contact-information__icon" icon={faPhoneAlt} />
					<span className="bsn-contact-information__text">{Translate("identity.phone1")}</span>
				</li>
				<li className="bsn-contact-information__item bsn-contact-information__item--phone">
					<FontAwesomeIcon className="bsn-contact-information__icon" icon={faPhoneAlt} />
					<span className="bsn-contact-information__text">{Translate("identity.phone2")}</span>
				</li>
				<li className="bsn-contact-information__item bsn-contact-information__item--email">
					<FontAwesomeIcon className="bsn-contact-information__icon" icon={faEnvelope} />
					<a href={`mailto:${Config.email.contact}`} className="bsn-contact-information__text bsn-contact-information__link">{Config.email.contact}</a>
				</li>
				<li className="bsn-contact-information__item bsn-contact-information__item--address">
					<FontAwesomeIcon className="bsn-contact-information__icon" icon={faMapMarkerAlt} />
					<span className="bsn-contact-information__text">{Translate("identity.address")}</span>
				</li>
			</ul>
		);
	}
}

export default BSNContactInformation;
