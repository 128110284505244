import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Translate from 'react-translate-component';
import CMPPlainHTML from './../components/cmp-plain-html';
import CMPContent from './../components/cmp-content';
import CMPHeader from './../components/cmp-header';
import BSNNavigationPanel from './../components/business/bsn-navigation-panel';
import BSNMain from './../components/business/bsn-main';
import BSNHeader from './../components/business/bsn-header';
import BSNFooter from './../components/business/bsn-footer';
import { faLayerGroup, faFireExtinguisher, faTintSlash, faVolumeMute, faThermometerHalf } from '@fortawesome/free-solid-svg-icons';

import "./../stylesheets/pages/services.sass";

class Services extends React.Component {

	render() {

		return (
			<div id="services">

				<BSNHeader>
					<BSNNavigationPanel language={this.props.lang} active="services" />
					<CMPHeader title={<Translate content="menu.services" />} />
				</BSNHeader>

				<BSNMain>

						<CMPContent className="content">
							<CMPPlainHTML>
								<section className="active" data-index="1" data-item="answer">
									<header>
										<h2 data-index="1" data-trigger="answer"><Translate content="pages.services.section.one.title" /></h2>
									</header>
									<article className="gnc text-block">
										<Translate content="pages.services.section.one.description" component="p" />
									</article>
								</section>
								<section data-index="2" data-item="answer">
									<header>
										<h2 data-index="2" data-trigger="answer"><Translate content="pages.services.section.two.title" /></h2>
									</header>
									<article className="gnc text-block">
										<Translate content="pages.services.section.two.description" component="p" />
									</article>
								</section>
								<section data-index="3" data-item="answer">
									<header>
										<h2 data-index="3" data-trigger="answer"><Translate content="pages.services.section.three.title" /></h2>
									</header>
									<article className="gnc text-block">
										<Translate content="pages.services.section.three.description" component="p" />
									</article>
								</section>
								<section data-index="4" data-item="answer">
									<header>
										<h2 data-index="4" data-trigger="answer"><Translate content="pages.services.section.four.title" /></h2>
									</header>
									<article className="gnc text-block">
										<Translate content="pages.services.section.four.description" component="p" />
									</article>
								</section>
								<section data-index="5" data-item="answer">
									<header>
										<h2 data-index="5" data-trigger="answer"><Translate content="pages.services.section.five.title" /></h2>
									</header>
									<article className="gnc text-block">
										<Translate content="pages.services.section.five.description" component="p" />
									</article>
								</section>
								<section data-index="6" data-item="answer">
									<header>
										<h2 data-index="6" data-trigger="answer"><Translate content="pages.services.section.six.title" /></h2>
									</header>
									<article className="gnc text-block">
										<Translate content="pages.services.section.six.description" component="p" />
									</article>
								</section>
							</CMPPlainHTML>
						</CMPContent>

						<CMPContent className="sidebar">

							<section>
								<header>
									<h2><Translate content="pages.services.categories" /></h2>
								</header>

								<aside className="service-selection">
									<nav>
										<ul>
											<li className="active" data-index="1" data-trigger="question" data-item="question"><FontAwesomeIcon className="icon" fixedWidth={true} icon={faLayerGroup} /><Translate content="pages.services.lamination" /></li>
											<li data-index="2" data-trigger="question" data-item="question"><FontAwesomeIcon className="icon" fixedWidth={true} icon={faTintSlash} /><Translate content="pages.services.waterproofing" /></li>
											<li data-index="3" data-trigger="question" data-item="question"><FontAwesomeIcon className="icon" fixedWidth={true} icon={faThermometerHalf} /><Translate content="pages.services.thermalInsulation" /></li>
											<li data-index="4" data-trigger="question" data-item="question"><FontAwesomeIcon className="icon" fixedWidth={true} icon={faVolumeMute} /><Translate content="pages.services.soundproofing" /></li>
											<li data-index="5" data-trigger="question" data-item="question"><FontAwesomeIcon className="icon" fixedWidth={true} icon={faFireExtinguisher} /><Translate content="pages.services.fireproofing" /></li>
											<li data-index="6" data-trigger="question" data-item="question"><FontAwesomeIcon className="icon" fixedWidth={true} icon={faThermometerHalf} /><Translate content="pages.services.thermalInsulationCovers" /></li>
										</ul>
									</nav>
								</aside>
							</section>
						</CMPContent>

				</BSNMain>

				<BSNFooter />

			</div>
		);
	}
}

export default Services;
