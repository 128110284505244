import React from 'react';
import "./../stylesheets/components/cmp-error.sass";

class CMPError extends React.Component {

	render() {

		return (
			<div className="cmp-error">
				<h1 className="cmp-error__code">{this.props.code}</h1>
				<h2 className="cmp-error__title">{this.props.title}</h2>
				{this.props.action ? this.props.action : null}
			</div>
		);
	}
};

export default CMPError;
