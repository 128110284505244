import React from 'react';
import Translate from 'react-translate-component';
import CMPHeader from './../components/cmp-header';
import CMPContent from './../components/cmp-content';
import CMPContactForm from './../components/cmp-contact-form';
import CMPMap from './../components/cmp-map';
import BSNMain from './../components/business/bsn-main';
import BSNNavigationPanel from './../components/business/bsn-navigation-panel';
import BSNHeader from './../components/business/bsn-header';
import BSNFooter from './../components/business/bsn-footer';
import BSNContactInformation from './../components/business/bsn-contact-information';

import "./../stylesheets/pages/contact.sass";

class Contact extends React.Component {

	render() {

		return (
			<div id="contact">

				<BSNHeader>
					<BSNNavigationPanel language={this.props.lang} active="contact" />
					<CMPHeader title={<Translate content="menu.contact" />} />
				</BSNHeader>

				<BSNMain>
					<CMPContent className="contact-info">
						<section>
							<header>
								<Translate content="pages.contact.info.title" component="h2" />
							</header>
							<BSNContactInformation />
						</section>
					</CMPContent>

					<CMPContent className="contact-form">
						<section>
							<header>
								<Translate content="pages.contact.form.title" component="h2" />
							</header>
							<CMPContactForm />
						</section>
					</CMPContent>

				</BSNMain>

				<CMPMap />

				<BSNFooter />

			</div>
		);
	}

}

export default Contact;
