import React from 'react';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "./../stylesheets/components/cmp-button.sass"

class CMPButton extends React.Component {

	render() {

		const classList = {
			root: 'cmp-button',
			type: this.props.type ? `cmp-button--${this.props.type}` : null,
			icon: this.props.icon ? 'cmp-button--with-icon': null
		};

		const iconTemplate = this.props.icon ? <FontAwesomeIcon className="cmp-button__icon" icon={this.props.icon} /> : null;
		const textTemplate = <span className="cmp-button__text">{this.props.text}</span>;

		if (this.props.to) {
			return (
				<Link className={Object.values(classList).map((style) => style).join(' ')} to={this.props.to}>
					{iconTemplate}
					{textTemplate}
				</Link>
			)
		}

		else if (this.props.url) {
			return (
				<a className={Object.values(classList).map((style) => style).join(' ')} href={this.props.url}>
					{iconTemplate}
					{textTemplate}
				</a>
			)
		}

		else if (this.props.input) {
			return (
				<input type="submit" className={Object.values(classList).map((style) => style).join(' ')} value={this.props.text} />
			)
		}

		else if (this.props.action) {
			return (
				<span className={Object.values(classList).map((style) => style).join(' ')} onClick={this.props.action}>
					{iconTemplate}
					{textTemplate}
				</span>
			)
		}
		
		else {
			return (
				<span className={Object.values(classList).map((style) => style).join(' ')}>
					{iconTemplate}
					{textTemplate}
				</span>
			)
		}
	}
};

export default CMPButton;
