import Package from './../../package.json';

const identifier = 'kouz';

const keys = {
	CURRENT_LANG: `${identifier}-current-language-${Package.version}`,
	USER_TOKEN: `${identifier}-user-token-${Package.version}`,
	USER_TOKEN_EXPIRATION: `${identifier}-user-token-exp-${Package.version}`,
	USER_ID: `${identifier}-user-id-${Package.version}`
};

const checkStoredObject = (key) => localStorage.getItem(key) === null || localStorage.getItem(key) === undefined ? false : true;

const getStoredObject = (key) => localStorage.getItem(key);

const removeStoredObject = (key) => localStorage.removeItem(key);

const storeObject = (key, value, { force = false } = {}) => {
	if (!checkStoredObject(key) || force) {
		localStorage.setItem(key, value);
		return true;
	}
	return false;
}

export {
	checkStoredObject as CheckStoredObject,
	getStoredObject as GetStoredObject,
	removeStoredObject as RemoveStoredObject,
	storeObject as StoreObject,
	keys as Keys
};
