import React from 'react';
import "./../stylesheets/components/cmp-plain-html.sass"

class CMPPlainHTML extends React.Component {

	render() {
		return (
			<div className="cmp-plain-html">
				{this.props.children}
			</div>
		);
	}
}

export default CMPPlainHTML;
