import React from 'react';
import "./../stylesheets/components/cmp-content.sass"

class CMPContent extends React.Component {

	render() {

		const classList = {
			root: 'cmp-content',
			class: this.props.className ? this.props.className : null
		};

		return (
			<div className={Object.values(classList).map((style) => style).join(' ')}>
				{this.props.children}
			</div>
		);
	}
}

export default CMPContent;
