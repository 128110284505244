import React from 'react';
import "./../stylesheets/components/cmp-header.sass"

class CMPHeader extends React.Component {

	render() {
		return (
			<header className="cmp-header">

				{this.props.children}

				<div className="template-header template-header-home">
					<h1 className="cmp-header__title">{this.props.title}</h1>
				</div>

			</header>
		);
	}
}

export default CMPHeader;
