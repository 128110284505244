export default {
	environment: "development",
	protocol: "https",
	domain: "dev.kouzoglouinsulations.gr",
	url: "https://dev.kouzoglouinsulations.gr",
	API: {
		url: "https://dapi.kouzoglouinsulations.gr/v1"
	},
	email: {
		contact: "info@kouzoglouinsulations.gr",
		administrator: "administrator@kouzoglouinsulations.gr"
	},
	google: {
		tagManager: {
			key: "XXX-1234567"
		},
		maps: {
			key: "sample-AP1-KE7"
		}
	}
}
