import * as Core from './core';
import "./../stylesheets/modules/mdl-loader.sass"

class MdlLoader {
	constructor({ selector = 'body', opacity = 1 } = {}) {
		this.selector = selector;
		this.node = document.querySelector(selector);
		this.opacity = opacity;
	}

	renderTemplate() {
		// Insert rendered template to the DOM
		const renderedTemplate = this.template();
		this.node.insertAdjacentHTML('beforeend', renderedTemplate);
		return document.querySelector(`#mdl-loader-${this._id}`);
	}

	template() {
		return `<aside id="mdl-loader-${this._id}" class="mdl-loader">
							<figure class="mdl-loader__spinner">
								<img class="mdl-loader__image" src="/images/loader.gif" alt="loader" title="loading...">
							</figure>
						</aside>`;
	}

	Start() {
		this._id = Core.Id();
		const element = this.renderTemplate();

		// add some time for the element to rest after rendering
		// in order to make the animation really visible
		setTimeout(() => {
			this.node.style.position = 'relative';
			element.style.visibility = 'visible';
			element.style.opacity = this.opacity;
		}, 50);

		return element;
	}

	Stop() {
		const element = this.node.querySelector(`#mdl-loader-${this._id}`);
		element.style.opacity = 0;
		element.style.visibility = 'hidden';

		// remove target element
		setTimeout(() => {
			this.node.removeChild(element);
		}, 500);

		return null;
	}

	static DEFAULT_OPACITY = 0.9;
}

export default MdlLoader;
