import React from 'react';
import "./../../stylesheets/components/business/bsn-main.sass"

class BSNMain extends React.Component {

	render() {

		return (
			<main className="bsn-main">
				{this.props.children}
			</main>
		);
	}
}

export default BSNMain;
