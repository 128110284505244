import React from 'react';
import Translate from 'react-translate-component';
import CMPHeader from './../components/cmp-header';
import CMPContent from './../components/cmp-content';
import BSNMain from './../components/business/bsn-main';
import BSNNavigationPanel from './../components/business/bsn-navigation-panel';
import BSNGallery from './../components/business/bsn-gallery';
import BSNHeader from './../components/business/bsn-header';
import BSNFooter from './../components/business/bsn-footer';

import "./../stylesheets/pages/gallery.sass";

class Gallery extends React.Component {

	render() {

		return (
			<div id="gallery">

				<BSNHeader>
					<BSNNavigationPanel language={this.props.lang} active="gallery" />
					<CMPHeader title={<Translate content="menu.gallery" />} />
				</BSNHeader>

				<BSNMain>

					<CMPContent className="content">
						<header>
							<Translate content="pages.gallery.section.one.title" component="h2" />
						</header>
						{/* <article>
							<Translate content="pages.gallery.section.one.description" component="p" />
						</article> */}
						<BSNGallery />
					</CMPContent>

				</BSNMain>

				<BSNFooter />

			</div>
		);
	}
}

export default Gallery;
