import React from 'react';
import ee from 'event-emitter';
import Translate from 'react-translate-component';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons';
import "./../stylesheets/components/cmp-menu.sass"

const emitter = new ee();

export const Close = (event) => {
	emitter.emit('CMP:Menu:Close', event);
}

export const Open = (event) => {
	emitter.emit('CMP:Menu:Open', event);
}

export class CMPMenu extends React.Component {

	constructor(props) {
		super(props);
		this.state = { show: this.props.show };

		this.open = this.open.bind(this);
		this.close = this.close.bind(this);
		this.toggleMenu = this.toggleMenu.bind(this);

		emitter.on('CMP:Menu:Open', this.open);
		emitter.on('CMP:Menu:Close', this.close);
	}

	close(e) {
		e.stopPropagation();
		this.setState({ show: false });
	}

	open(e) {
		e.stopPropagation();
		this.setState({ show: true });
	}

	toggleMenu(e) {
		e.stopPropagation();
		this.setState({ show: !this.state.show });
	}

	render() {

		// bug! Set state only to appropriate cmp-menu instance!!!
		const classList = {
			root: 'cmp-menu',
			mobile: this.props.mobile ? 'cmp-menu--mobile' : null,
			layout: this.props.layout ? `cmp-menu--${this.props.layout}` : null,
			visibility: this.state.show ? 'cmp-menu--show' : 'cmp-menu--hide'
		};

		return (

			this.props.mobile ?

			<nav className={Object.values(classList).map((style) => style).join(' ')}>
			<CMPMenuHandler />
				<ul className="cmp-menu__list">
					{this.props.children}
				</ul>
			</nav>

			:

			<nav className={Object.values(classList).map((style) => style).join(' ')}>
				<ul className="cmp-menu__list">
					{this.props.children}
				</ul>
			</nav>

		);
	}
};

export class CMPMenuItem extends React.Component {

	render() {

		const classList = {
			root: 'cmp-menu__item',
			active: this.props.active ? 'cmp-menu__item--active': null,
			hover: this.props.hover ? `cmp-menu__item--hover` : null,
		};

		return (
			<li className={Object.values(classList).map((style) => style).join(' ')}>


				{this.props.path
					? <Link className="cmp-menu__title" to={this.props.path}>
							{this.props.title}
						</Link>
					: null
				}

				{this.props.action
					? <div className="cmp-menu__title" onClick={this.props.action}>
							{this.props.title}
						</div>
					: null
				}
			</li>
		);
	}
};

export class CMPMenuHandler extends React.Component {

	render() {

		const classList = {
			root: 'cmp-menu-handler',
		};

		return (
			<div className={Object.values(classList).map((style) => style).join(' ')} onClick={Open}>
				<FontAwesomeIcon className="cmp-menu-handler__icon" icon={faBars} />
				<Translate content="menu.handler" component="span" className="cmp-menu-handler__text" />
			</div>
		);
	}
};
