import React from 'react';

import "./../../stylesheets/components/business/bsn-header.sass"

class BSNHeader extends React.Component {

	render() {

		return (
			<div className="bsn-header">
				{this.props.children}
			</div>
		);
	}
}

export default BSNHeader;
