import React from 'react';
import ee from 'event-emitter';
import { Link } from "react-router-dom";

const emitter = new ee();

export const Notify = (message, options) => {
	emitter.emit('MDC:Snackbar:Notify', message, options); // { buttonText, action, delayTime,  }
}

class MDCSnackbar extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			action: undefined, // method to trigger after button press
			path: '', // when indicated a Link component will be used instead of a simple function call
			buttonText: '', // action button text
			delay: 5000, // time in ms of notification's appearance
			message: '', // information text
			show: false, // show / hide notification
			status: 'basic' // color of action button's text (depending on status [ basic | error | info | success | warning ])
		}

		this.timeout = null;
		this.notify = this.notify.bind(this);
		this.handleOnClick = this.handleOnClick.bind(this);

		emitter.on('MDC:Snackbar:Notify', this.notify);
	}

	handleOnClick(e) {
		this.setState({ show: false });

		if (!this.state.action || typeof this.state.action !== 'function') return;
		this.state.action();
	}

	notify(message, { action, buttonText, path, delay, status }) {
		this.setState({ message, action, buttonText, path, delay, show: true, status },
			() => {
				this.timeout = setTimeout(() => this.setState({ show: false },
					() => {
						clearTimeout(this.timeout); }),
				delay || 5000)
			});
	}

	render() {

		const classList = {
			root: "mdc-snackbar",
			show: this.state.show ? `mdc-snackbar--show` : null,
			status: this.state.status ? `mdc-snackbar--${this.state.status}` : 'mdc-snackbar--basic'
		};

		return (
			<div className={Object.values(classList).map((style) => style).join(' ')}>
					<p className="mdc-snackbar__message">{this.state.message}</p>
					{!this.state.path ? <button className="mdc-snackbar__action" onClick={this.handleOnClick}>{this.state.buttonText}</button> : null }
					{this.state.path ? <Link to={this.state.path} className="mdc-snackbar__action">{this.state.buttonText}</Link> : null}
			</div>
		)
	}
}

export default MDCSnackbar;
