class HResponsive {

	constructor() {
		this.MOBILE_BREAKPOINT = 480;
		this.TABLET_BREAKPOINT = 720;
		this.DESKTOP_BREAKPOINT = 960;
	}

	GetDevice() {
		if (window.innerWidth <= this.TABLET_BREAKPOINT) {
			return 'device--mobile';
		}
		else if (window.innerWidth > this.TABLET_BREAKPOINT && window.innerWidth <= this.DESKTOP_BREAKPOINT) {
			return 'device--tablet';
		}
		else if (window.innerWidth > this.DESKTOP_BREAKPOINT) {
			return 'device--desktop';
		}
	}

	IsDesktop() {
		if (window.innerWidth > this.DESKTOP_BREAKPOINT)
			return true;
		return false;
	}

	IsTablet() {
		if (window.innerWidth > this.TABLET_BREAKPOINT && window.innerWidth <= this.DESKTOP_BREAKPOINT)
			return true;
		return false;
	}

	IsMobile() {
		if (window.innerWidth <= this.TABLET_BREAKPOINT)
			return true;
		return false;
	}

};

export default HResponsive;
