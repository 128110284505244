import React from 'react';
import Translate from 'counterpart';
import CMPButton from './../cmp-button';
import "./../../stylesheets/components/business/bsn-seo-banner.sass"

class BSNSEOBanner extends React.Component {

	render() {

		return (
			<section className="bsn-seo-banner">

				<h1 className="bsn-seo-banner__title">{Translate("pages.home.seo.title")}</h1>
				<p className="bsn-seo-banner__description">{Translate("pages.home.seo.description")}</p>

				<CMPButton to="/services" text={Translate("pages.home.seo.cta1")} type="inverted" />
				{/*<CMPButton to="/gallery" text={Translate("pages.home.seo.cta2")} type="hollow" />*/}
			</section>
		);
	}
}

export default BSNSEOBanner;
