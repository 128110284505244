import React from 'react';
import Translate from 'counterpart';
import Config from './../config';
import API from './../helpers/api';
import Security from './../helpers/security';
import { ValidateField } from './../helpers/validation'
import MDLLoader from './../modules/mdl-loader';
import { Notify as MDCSnackbarNotify } from './material/mdc-snackbar';
import CMPButton from './cmp-button';
import CMPTextField from './cmp-text-field';
import CMPModal, { CMPModalManager } from './cmp-modal';
import CMPLink from './cmp-link';
import Terms from './../pages/terms';
import { faUser, faEnvelope, faPhoneAlt, faCaretDown } from '@fortawesome/free-solid-svg-icons';
import "./../stylesheets/components/cmp-contact-form.sass"


class CMPContactForm extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			disableForm: false,
			name: '',
			nameError: '',
			phone: '',
			phoneError: '',
			email: '',
			emailError: '',
			subject: '',
			subjectError: '',
			message: '',
			messageError: '',
			gdpr: false,
			gdprError: ''
		};

		this.checkServiceStatus = this.checkServiceStatus.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleInputBlur = this.handleInputBlur.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.validateForm = this.validateForm.bind(this);

		this.validations = {
			name: [{ code: "required", message: Translate("pages.contact.form.fields.name.validation.required") }],
			phone: [{ code: "required", message: Translate("pages.contact.form.fields.phone.validation.required") }, { code: "min-chars", value: 10 }, { code: "max-chars", value: 13 }],
			email: [{ code: "required", message: Translate("pages.contact.form.fields.email.validation.required") }, { code: "email", message: Translate("pages.contact.form.fields.email.validation.email") }],
			subject: [{ code: "required", message: Translate("pages.contact.form.fields.subject.validation.required") }],
			message: [{ code: "required", message: Translate("pages.contact.form.fields.message.validation.required") }],
			gdpr: [{ code: "required", message: Translate("pages.contact.form.fields.gdpr.validation.required") }]
		};
	}

	handleInputBlur({ name, value }) {

		const { isValid, errors } = ValidateField(value, this.validations[name]);

		this.setState({
			[`${name}Error`]: !isValid && errors.length > 0 ? errors[0].message : false,
		});
	}

	handleInputChange({ name, value }) {
		this.setState({ [name]: value });
	}

	async handleSubmit(e) {
		e.preventDefault();

		const isFormValid = this.validateForm();

		if (!isFormValid) { return; }

		// Collect form data
		const formData = {
			name: this.state.name,
			phone: this.state.phone,
			email: this.state.email,
			subject: this.state.subject,
			message: this.state.message,
			gdpr: this.state.gdpr,
			language: Translate.getLocale()
		};

		const loader = new MDLLoader({ selector: '.cmp-contact-form', opacity: MDLLoader.DEFAULT_OPACITY });
		loader.Start();

		// Make call to the API
		const endpoint = API.endpoints.messages.create();
		const response = await API.request(endpoint, formData).then(API.response);

		loader.Stop();
		
		if (!response.success)
			MDCSnackbarNotify(Translate("api.SUBMIT_FAIL.message"), { buttonText: 'DISMISS', delay: 8000, status: 'error' });
		else
			MDCSnackbarNotify(Translate("api.SUBMIT_SUCCESS.message"), { buttonText: 'OK', delay: 8000, status: 'success' });

		// Reset form
		this.resetForm();
	}

	resetForm() {
		this.setState({ name: '', phone: '', email: '', subject: '', message: '', gdpr: false });
	}

	validateForm() {

		const { isValid: nameValid, errors: nameErrors } = ValidateField(this.state.name, this.validations.name);
		const { isValid: phoneValid, errors: phoneErrors } = ValidateField(this.state.phone, this.validations.phone);
		const { isValid: emailValid, errors: emailErrors } = ValidateField(this.state.email, this.validations.email);
		const { isValid: subjectValid, errors: subjectErrors } = ValidateField(this.state.subject, this.validations.subject);
		const { isValid: messageValid, errors: messageErrors } = ValidateField(this.state.message, this.validations.message);
		const { isValid: gdprValid, errors: gdprErrors } = ValidateField(this.state.gdpr, this.validations.gdpr);

		this.setState({
			nameError: !nameValid && nameErrors.length > 0 ? nameErrors[0].message : false,
			phoneError: !phoneValid && phoneErrors.length > 0 ? phoneErrors[0].message : false,
			emailError: !emailValid && emailErrors.length > 0 ? emailErrors[0].message : false,
			subjectError: !subjectValid && subjectErrors.length > 0 ? subjectErrors[0].message : false,
			messageError: !messageValid && messageErrors.length > 0 ? messageErrors[0].message : false,
			gdprError: !gdprValid && gdprErrors.length > 0 ? gdprErrors[0].message : false
		});

		if (!nameValid || !phoneValid || !emailValid || !subjectValid || !messageValid || !gdprValid) { return false; }

		return true;
	}

	async checkServiceStatus() {

		const loader = new MDLLoader({ selector: '.cmp-contact-form', opacity: MDLLoader.DEFAULT_OPACITY });
		loader.Start();

		// Make a call to verify the user's authenticity
		const authorizationResponse = await Security.authorize();
		const checkResponse = await API.request(API.endpoints.check()).then(API.response);

		loader.Stop();

		if (!authorizationResponse.success) {
			MDCSnackbarNotify(Translate("api.USER_NOT_AUTHORIZED.message"), { buttonText: 'DISMISS', status: 'error' });
			this.setState({ disableContactForm: true });
		}
		
		else if (!checkResponse.success) {
			MDCSnackbarNotify(Translate("api.SERVICES_UNAVAILABLE.message"), { buttonText: 'DISMISS', status: 'error' });
			this.setState({ disableContactForm: true });
		}	
	}

	render() {

		const classList = {
			root: 'cmp-contact-form',
			disabled: this.state.disableContactForm ? 'cmp-contact-form--disabled' : null,
		};

		const terms = (
			<span>
				{Translate("pages.contact.form.fields.gdpr.title")} <CMPLink action={(e) => CMPModalManager.Open(e)} text={Translate("pages.contact.form.fields.gdpr.terms")} />
			</span>
		)

		return (
			<form className={Object.values(classList).map((style) => style).join(' ')} onSubmit={this.handleSubmit}>
				{this.state.disableContactForm ? <p className="cmp-contact-form__notification">{Translate("pages.contact.form.unavailable.message")}<CMPLink url={`mailto:${Config.email.administrator}`} text={Config.email.administrator} /></p> : ''}

				<div className="cmp-contact-form__field">
					<CMPTextField
						id="cf-name"
						name="name"
						label={Translate("pages.contact.form.fields.name.title")}
						placeholder={Translate("pages.contact.form.fields.name.placeholder")}
						value={this.state.name}
						errorText={this.state.nameError}
						valid={!this.state.nameError}
						icon={faUser}
						onChange={this.handleInputChange}
						onBlur={this.handleInputBlur}
					/>
				</div>
				<div className="cmp-contact-form__field">
					<CMPTextField
						id="cf-phone"
						name="phone"
						type="number"
						label={Translate("pages.contact.form.fields.phone.title")}
						placeholder={Translate("pages.contact.form.fields.phone.placeholder")}
						value={this.state.phone}
						errorText={this.state.phoneError}
						valid={!this.state.phoneError}
						icon={faPhoneAlt}
						onChange={this.handleInputChange}
						onBlur={this.handleInputBlur}
					/>
				</div>

				<div className="cmp-contact-form__field">
					<CMPTextField
						id="cf-email"
						name="email"
						type="email"
						label={Translate("pages.contact.form.fields.email.title")}
						placeholder={Translate("pages.contact.form.fields.email.placeholder")}
						value={this.state.email}
						errorText={this.state.emailError}
						valid={!this.state.emailError}
						icon={faEnvelope}
						onChange={this.handleInputChange}
						onBlur={this.handleInputBlur}
					/>
				</div>
				<div className="cmp-contact-form__field">
					<CMPTextField
						id="cf-subject"
						name="subject"
						type="dropdown"
						label={Translate("pages.contact.form.fields.subject.title")}
						value={this.state.subject}
						errorText={this.state.subjectError}
						valid={!this.state.subjectError}
						icon={faCaretDown}
						onChange={this.handleInputChange}
						onBlur={this.handleInputBlur}
					>
						<option value="" default={true} disabled={true}>{Translate("pages.contact.form.fields.subject.options.default")}</option>
						<option value={Translate("pages.contact.form.fields.subject.options.question")}>{Translate("pages.contact.form.fields.subject.options.question")}</option>
						<option value={Translate("pages.contact.form.fields.subject.options.clarification")}>{Translate("pages.contact.form.fields.subject.options.clarification")}</option>
						<option value={Translate("pages.contact.form.fields.subject.options.complaint")}>{Translate("pages.contact.form.fields.subject.options.complaint")}</option>
						<option value={Translate("pages.contact.form.fields.subject.options.proposal")}>{Translate("pages.contact.form.fields.subject.options.proposal")}</option>
						<option value={Translate("pages.contact.form.fields.subject.options.other")}>{Translate("pages.contact.form.fields.subject.options.other")}</option>
					</CMPTextField>
				</div>
				<div className="cmp-contact-form__field cmp-contact-form__field--fullsize">
					<CMPTextField
						id="cf-message"
						name="message"
						type="textarea"
						label={Translate("pages.contact.form.fields.message.title")}
						placeholder={Translate("pages.contact.form.fields.message.placeholder")}
						value={this.state.message}
						errorText={this.state.messageError}
						valid={!this.state.messageError}
						onChange={this.handleInputChange}
						onBlur={this.handleInputBlur}
					/>
				</div>
				<div className="cmp-contact-form__field cmp-contact-form__field--fullsize">
					<CMPTextField
						id="cf-gdpr"
						name="gdpr"
						type="checkbox"
						label={terms}
						placeholder={Translate("pages.contact.form.fields.gdpr.placeholder")}
						checked={this.state.gdpr}
						value={this.state.gdpr}
						errorText={this.state.gdprError}
						valid={!this.state.gdprError}
						onChange={this.handleInputChange}
						onBlur={this.handleInputBlur}
					/>
				</div>
				<div className="cmp-contact-form__field">
					<CMPButton type="inverted" text={Translate("pages.contact.form.fields.submit.title")} input={this.handleSubmit} />
				</div>

				<CMPModal>
					<Terms />
				</CMPModal>
			</form>
		);
	}

	componentDidMount() {
		this.checkServiceStatus();
	}
}

export default CMPContactForm;
