import React from 'react';
import Translate from 'counterpart';
import Config from './../config';
import API from './../helpers/api';
import Security from './../helpers/security';
import { ValidateField } from './../helpers/validation'
import MDLLoader from './../modules/mdl-loader';
import { Notify as MDCSnackbarNotify } from './material/mdc-snackbar';
import CMPButton from './cmp-button';
import CMPTextField from './cmp-text-field';
import CMPLink from './cmp-link';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

import "./../stylesheets/components/cmp-opt-out-form.sass"

class CMPOptOutForm extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			disableForm: false,
			email: '',
			emailError: '',
		};

		this.checkServiceStatus = this.checkServiceStatus.bind(this);
		this.handleInputBlur = this.handleInputBlur.bind(this);
		this.handleInputChange = this.handleInputChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.validateForm = this.validateForm.bind(this);

		this.validations = {
			email: [{ code: "required", message: Translate("pages.contact.form.fields.email.validation.required") }, { code: "email", message: Translate("pages.contact.form.fields.email.validation.email") }],
		};
	}

	handleInputBlur({ name, value }) {

		const { isValid, errors } = ValidateField(value, this.validations[name]);

		this.setState({
			[`${name}Error`]: !isValid && errors.length > 0 ? errors[0].message : false,
		});
	}

	handleInputChange({ name, value }) {
		this.setState({ [name]: value });
	}

	async handleSubmit(e) {
		e.preventDefault();

		const isFormValid = this.validateForm();

		if (!isFormValid) { return; }

		// Collect form data
		const formData = {
			email: this.state.email
		};

		const loader = new MDLLoader({ selector: '.cmp-opt-out-form', opacity: MDLLoader.DEFAULT_OPACITY });
		loader.Start();

		// Make call to the API
		const endpoint = API.endpoints.unsubsribes.create();
		const response = await API.request(endpoint, formData).then(API.response);

		loader.Stop();
		
		if (!response.success)
			MDCSnackbarNotify(Translate("api.OPTOUT_FAIL.message"), { buttonText: 'DISMISS', delay: 8000, status: 'error' });
		else
			MDCSnackbarNotify(Translate("api.OPTOUT_SUCCESS.message"), { buttonText: 'OK', delay: 8000, status: 'success' });

		// Reset form
		this.resetForm();
	}

	resetForm() {
		this.setState({ email: '' });
	}

	validateForm() {

		const { isValid: emailValid, errors: emailErrors } = ValidateField(this.state.email, this.validations.email);

		this.setState({
			emailError: !emailValid && emailErrors.length > 0 ? emailErrors[0].message : false
		});

		if (!emailValid) { return false; }

		return true;
	}

	async checkServiceStatus() {
		const loader = new MDLLoader({ selector: '.cmp-opt-out-form', opacity: MDLLoader.DEFAULT_OPACITY });
		loader.Start();

		// Make a call to verify the user's authenticity
		const authorizationResponse = await Security.authorize();
		const checkResponse = await API.request(API.endpoints.check()).then(API.response);

		loader.Stop();

		if (!authorizationResponse.success) {
			MDCSnackbarNotify(Translate("api.USER_NOT_AUTHORIZED.message"), { buttonText: 'DISMISS', status: 'error' });
			this.setState({ disableForm: true });
		}
		
		else if (!checkResponse.success) {
			MDCSnackbarNotify(Translate("api.SERVICES_UNAVAILABLE.message"), { buttonText: 'DISMISS', status: 'error' });
			this.setState({ disableForm: true });
		}
	}

	render() {

		const classList = {
			root: 'cmp-opt-out-form',
			disabled: this.state.disableForm ? 'cmp-opt-out-form--disabled' : null,
		};

		return (
			<form className={Object.values(classList).map((style) => style).join(' ')} onSubmit={this.handleSubmit}>
				{this.state.disableForm ? <p className="cmp-opt-out-form__notification">{Translate("forms.generic.unavailable.message")}<CMPLink url={`mailto:${Config.email.administrator}`} text={Config.email.administrator} /></p> : ''}

				<div className="cmp-opt-out-form__field">
					<CMPTextField
						id="cf-email"
						name="email"
						type="email"
						label={Translate("forms.optOut.fields.email.title")} 
						placeholder={Translate("forms.optOut.fields.email.placeholder")} 
						required={true}
						value={this.state.email}
						errorText={this.state.emailError}
						valid={!this.state.emailError}
						icon={faEnvelope}
						onChange={this.handleInputChange}
						onBlur={this.handleInputBlur}
					/>
				</div>
				
				<div className="cmp-opt-out-form__field">
					<CMPButton type="inverted" text={Translate("forms.optOut.fields.submit")} input={this.handleSubmit} />
				</div>
			</form>
		);
	}

	componentDidMount() {
		this.checkServiceStatus();
	}
};

export default CMPOptOutForm;
