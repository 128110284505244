import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ValidateField } from './../helpers/validation'
import "./../stylesheets/components/cmp-text-field.sass"

class CMPTextFieldCheckbox extends React.Component {
	render() {
		return <input className="cmp-text-field__input cmp-text-field__input--checkbox"
									type="checkbox"
									id={this.props.id}
									name={this.props.name}
									value={this.props.value}
									checked={this.props.checked}
									onChange={this.props.onChange}
									onFocus={this.props.onFocus}
									onBlur={this.props.onBlur} />
	}
}

class CMPTextFieldArea extends React.Component {
	render() {
		return (
			<textarea className="cmp-text-field__input cmp-text-field__input--textarea"
				id={this.props.id}
				name={this.props.name}
				value={this.props.value}
				placeholder={this.props.placeholder}
				onChange={this.props.onChange}
				onFocus={this.props.onFocus}
				onBlur={this.props.onBlur} />
		)
	}
}

class CMPTextFieldInput extends React.Component {
	render() {
		return <input className="cmp-text-field__input"
									type="text"
									id={this.props.id}
									name={this.props.name}
									value={this.props.value}
									placeholder={this.props.placeholder}
									onChange={this.props.onChange}
									onFocus={this.props.onFocus}
									onBlur={this.props.onBlur} />
	}
}

class CMPTextFieldNumber extends React.Component {
	render() {
		return <input className="cmp-text-field__input cmp-text-field__input--number"
									type="number"
									id={this.props.id}
									name={this.props.name}
									value={this.props.value}
									placeholder={this.props.placeholder}
									step={this.props.step || 1}
									onChange={this.props.onChange}
									onFocus={this.props.onFocus}
									onBlur={this.props.onBlur} />
	}
}

class CMPTextFieldDate extends React.Component {
	render() {
		return <input className="cmp-text-field__input cmp-text-field__input--date"
									type="date"
									id={this.props.id}
									name={this.props.name}
									value={this.props.value}
									placeholder={this.props.placeholder}
									onChange={this.props.onChange}
									onFocus={this.props.onFocus}
									onBlur={this.props.onBlur} />
	}
}

class CMPTextFieldEmail extends React.Component {
	render() {
		return <input className="cmp-text-field__input cmp-text-field__input--email"
									type="email"
									id={this.props.id}
									name={this.props.name}
									value={this.props.value}
									placeholder={this.props.placeholder}
									onChange={this.props.onChange}
									onFocus={this.props.onFocus}
									onBlur={this.props.onBlur} />
	}
}

class CMPTextFieldPassword extends React.Component {
	render() {
		return <input className="cmp-text-field__input cmp-text-field__input--password"
									type="password"
									id={this.props.id}
									name={this.props.name}
									value={this.props.value}
									placeholder={this.props.placeholder}
									onChange={this.props.onChange}
									onFocus={this.props.onFocus}
									onBlur={this.props.onBlur} />
	}
}

class CMPTextFieldDropDown extends React.Component {
	render() {
		return <select className="cmp-text-field__input cmp-text-field__input--dropdown"
						id={this.props.id}
						name={this.props.name}
						value={this.props.value}
						placeholder={this.props.placeholder}
						onChange={this.props.onChange}
						onFocus={this.props.onFocus}
						onBlur={this.props.onBlur}>
						{this.props.children}
				</select>
	}
}

class CMPTextField extends React.Component {

	constructor(props) {
		super(props);

		this.state = { active: false }
		this.handleOnChange = this.handleOnChange.bind(this);
		this.handleOnFocus = this.handleOnFocus.bind(this);
		this.handleOnBlur = this.handleOnBlur.bind(this);
		this.checkIfValid = this.checkIfValid.bind(this);
	}

	checkIfValid(value, validations) {

		const { isValid, errors } = ValidateField(value, validations);

		this.setState({ 
			//valid: isValid, 
			errorText: !isValid && errors.length > 0 ? errors[0].message : false 
		});
	}

	handleOnChange(e) {
		const { name, value, checked } = e.target;

		if (this.props.onChange && typeof this.props.onChange === 'function') {
			if (this.props.type === "checkbox") {
				this.props.onChange({ name, value: checked });
			}
			else{
				this.props.onChange({ name, value });
			}
		}
	}

	handleOnFocus(e) {
		this.setState({ active: true });
	}

	handleOnBlur(e) {
		const { name, value, checked } = e.target;

		// if (this.props.type === "checkbox") {
		// 	this.checkIfValid(checked, this.props.validations);
		// }
		// else{
		// 	this.checkIfValid(value, this.props.validations);
		// }

		if (this.props.onBlur && typeof this.props.onBlur === 'function') {
			if (this.props.type === "checkbox") {
				this.props.onBlur({ name, value: checked });
			}
			else{
				this.props.onBlur({ name, value });
			}
		}

		this.setState({ active: false });
	}

	render() {

		const classList = {
			root: 'cmp-text-field',
			userClass: this.props.className ? this.props.className : null,
			dense: this.props.dense ? 'cmp-text-field--dense' : null,
			active: this.state.active ? 'cmp-text-field--active' : null,
			invalid: this.props.valid ? null : 'cmp-text-field--invalid',
			type: this.props.type === 'checkbox'
				? 'cmp-text-field--checkbox'
				: this.props.type === 'textarea'
					? 'cmp-text-field--textarea'
					: this.props.type === 'date'
						? 'cmp-text-field--date'
						: this.props.type === 'email'
							? 'cmp-text-field--email'
							: this.props.type === 'number'
								? 'cmp-text-field--number'
								: this.props.type === 'password'
									? 'cmp-text-field--password'
									: this.props.type === 'dropdown'
										? 'cmp-text-field--dropdown'
										: 'cmp-text-field--text'
		};

		let textFieldTemplate;

		switch(this.props.type) {
			case "checkbox":
				textFieldTemplate = <CMPTextFieldCheckbox id={this.props.id} name={this.props.name} value={this.props.value} checked={this.props.checked} onChange={this.handleOnChange} onFocus={this.handleOnFocus} onBlur={this.handleOnBlur} />;
				break;
			case "textarea":
				textFieldTemplate = <CMPTextFieldArea id={this.props.id} name={this.props.name} value={this.props.value} placeholder={this.props.placeholder} onChange={this.handleOnChange} onFocus={this.handleOnFocus} onBlur={this.handleOnBlur} />;
				break;
			case "date":
				textFieldTemplate = <CMPTextFieldDate id={this.props.id} name={this.props.name} value={this.props.value} placeholder={this.props.placeholder} onChange={this.handleOnChange} onFocus={this.handleOnFocus} onBlur={this.handleOnBlur} />;
				break;
			case "email":
				textFieldTemplate = <CMPTextFieldEmail id={this.props.id} name={this.props.name} value={this.props.value} placeholder={this.props.placeholder} onChange={this.handleOnChange} onFocus={this.handleOnFocus} onBlur={this.handleOnBlur} />;
				break;
			case "number":
				textFieldTemplate = <CMPTextFieldNumber id={this.props.id} name={this.props.name} value={this.props.value} placeholder={this.props.placeholder} step={this.props.step} onChange={this.handleOnChange} onFocus={this.handleOnFocus} onBlur={this.handleOnBlur} />;
				break;
			case "password":
				textFieldTemplate = <CMPTextFieldPassword id={this.props.id} name={this.props.name} value={this.props.value} placeholder={this.props.placeholder} onChange={this.handleOnChange} onFocus={this.handleOnFocus} onBlur={this.handleOnBlur} />;
				break;
			case "dropdown":
				textFieldTemplate = <CMPTextFieldDropDown id={this.props.id} name={this.props.name} value={this.props.value} placeholder={this.props.placeholder} onChange={this.handleOnChange} onFocus={this.handleOnFocus} onBlur={this.handleOnBlur}>{this.props.children}</CMPTextFieldDropDown>;
				break;
			default:
				textFieldTemplate = <CMPTextFieldInput id={this.props.id} name={this.props.name} value={this.props.value} placeholder={this.props.placeholder} onChange={this.handleOnChange} onFocus={this.handleOnFocus} onBlur={this.handleOnBlur} />;
				break;
		}

		const template = 
			this.props.type === "checkbox"
			? ( <div className={Object.values(classList).map((style) => style).join(' ')}>
					<div className="cmp-text-field__wrapper">
						{this.props.label ? <label className="cmp-text-field__label" htmlFor={this.props.id}>{this.props.label}</label> : null}
						{textFieldTemplate}
						{this.props.icon ? <FontAwesomeIcon className="cmp-text-field__icon" icon={this.props.icon} /> : null}
					</div>
					{this.props.errorText
						? <span className="cmp-text-field__helper-text">{this.props.errorText}</span>
						: <span className="cmp-text-field__helper-text">{this.props.helperText}</span>
					}
				</div>
			)
			: (
				<div className={Object.values(classList).map((style) => style).join(' ')}>
					{this.props.label ? <label className="cmp-text-field__label" htmlFor={this.props.id}>{this.props.label}</label> : null}
					{textFieldTemplate}
					{this.props.icon ? <FontAwesomeIcon className="cmp-text-field__icon" icon={this.props.icon} /> : null}
					{this.props.errorText
						? <span className="cmp-text-field__helper-text">{this.props.errorText}</span>
						: <span className="cmp-text-field__helper-text">{this.props.helperText}</span>
					}
				</div>
			)

		return (
			template
		)
	}
}

export default CMPTextField;

// TODO: check the validation process (required etc...)
