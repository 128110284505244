import React from 'react';
import ee from 'event-emitter';
import "./../stylesheets/components/cmp-overlay.sass"

const emitter = new ee();

export const Show = (message, options) => {
	emitter.emit('CMP:Overlay:Show');
}

export const Hide = (message, options) => {
	emitter.emit('CMP:Overlay:Hide');
}

class CMPOverlay extends React.Component {

	constructor(props) {
		super(props);

		this.state = { show: false };
		this.show = this.show.bind(this);
		this.hide = this.hide.bind(this);

		emitter.on('CMP:Overlay:Show', this.show);
		emitter.on('CMP:Overlay:Hide', this.hide);
	}

	show() {
		this.setState({ show: true });
	}

	hide() {
		this.setState({ show: false });
	}

	render() {
		return (
			<div className={`cmp-overlay ${this.state.show ? 'cmp-overlay--show' : 'cmp-overlay--hide' }`}>
			</div>
		);
	}
}

export default CMPOverlay;
