import React from 'react';
import Translate from 'react-translate-component';
import CMPHeader from './../components/cmp-header';
import CMPContent from './../components/cmp-content';
import CMPPlainHTML from './../components/cmp-plain-html';
import BSNMain from './../components/business/bsn-main';
import BSNNavigationPanel from './../components/business/bsn-navigation-panel';
import BSNHeader from './../components/business/bsn-header';
import BSNFooter from './../components/business/bsn-footer';

import "./../stylesheets/pages/clients.sass";

class Clients extends React.Component {

	render() {

		return (
			<div id="clients">

				<BSNHeader>
					<BSNNavigationPanel language={this.props.lang} active="clients" />
					<CMPHeader title={<Translate content="menu.clients" />} />
				</BSNHeader>

				<BSNMain>

					<CMPContent className="content">
						{/* <section>
							<header>
								<h2><Translate content="pages.clients.section.one.title" /></h2>
							</header>
							<article>
								<p><Translate content="pages.clients.section.one.description" /></p>
							</article>
						</section> */}

						<section>
							<header>
								<h2><Translate content="pages.clients.top.title" /></h2>
							</header>

							<CMPPlainHTML>

							<article>
								<ol>
									<li>ΣΕΡΕΣ ΝΑΥΤΙΛΙΑΚΑΙ ΕΠΙΧΕΙΡΗΣΕΙΣ Α.Ε.</li>
									<li>AGIS MARITIME LORD</li>
									<li>ALEXANDRIA SHIPPING S.A.</li>
									<li>ALIMOS YACHTING ΝΑΥΤΙΛΙΑΚΗ Α.Ε.</li>
									<li>ANBROS MARITIME (ΝΑΥΤΙΛΙΑΚΗ)</li>
									<li>ARKTOS MARITIME COMPANY OF PLEASURE YACHTS</li>
									<li>ATALANTA MARINE</li>
									<li>ATHENS COMPANY MARITIME </li>
									<li>ATLANTIC BULK CARRIERS MANAGEMENT LTD</li>
									<li>AVIN INTERNATIONAL LTD</li>
									<li>BILMAR YACHTING LTD</li>
									<li>BT PLEASURE YACHTING LIMITED</li>
									<li>CAPE SHIPPING SA </li>
									<li>CAPITAL SHIP MANAGEMENT CORP.</li>
									<li>CHRONOS SHIPPING LTD </li>
									<li>CONBULK SHIPPING SA </li>
									<li>COSTAMARE SHIPPING COMPANY </li>
									<li>DALUZ M.C.P.Y</li>
									<li>DANAOS SHIPPING CO LTD</li>
									<li>DOLPHIN CAPITAL YACHTS S.A.</li>
									<li>GENERAL MARITIME MANAGEMENT</li>
									<li>GENTROFIN MANAGEMENT ING </li>
									<li>GTS TRANSPORT (ΤΣΑΙΛΑΣ)</li>
									<li>INTERUNITY MANAGEMENT CORPORATION SA</li>
									<li>IRIS NAVIGATION LIMITED</li>
									<li>AEGEAN (ΜΕΛΙΣΣΑΝΙΔΗΣ)</li>
									<li>JET CRAFT SHIPYARD ΜΟΝΟΠΡΟΣΩΠΗ ΙΚΕ</li>
									<li>ΝΑΥΠΗΓΗΣΗ ΑΛΙΕΥΤΙΚΩΝ & ΑΛΛΩΝ ΣΚΑΦΩΝ</li>
									<li>JET MARITIME CORPORATION</li>
									<li>S/S CAPITAL SHIPPING</li>
									<li>LADY ELLEN SHIPPING COMPANY LIMITED</li>
									<li>LEVANTE FERRIES</li>
									<li>MAGNUS CARRIER CORPORATION (ΝΑΥΤΙΛΙΑΚΗ)</li>
									<li>MARMARAS NAVIGATION LTD</li>
									<li>MATHEW MARINE INCORP.</li>
									<li>MCCL</li>
									<li>MINERVA MARINE INC</li>
									<li>NOBEL MARITIME INC</li>
									<li>OCEAN WAVE MARITIME (ΝΑΥΤΙΛΙΑΚΗ)</li>
									<li>PARSIFAL YACHTING SA</li>
									<li>PLAXIVEN CONSULTING LTD</li>
									<li>PRIVATSEA YACHTING</li>
									<li>PROSPERITY BAY SHIPPING CO LTD</li>
									<li>REMI MARITIME</li>
									<li>S.FRANGOULIS (SHIP MANAGEMENT) LTD</li>
									<li>SALAMIS LINES LTD</li>
									<li>GOLDEN ANCHOR </li>
									<li>SEALIA LIMITED</li>
									<li>SHINKA SHIPPING CO S.A.</li>
									<li>SOLARIS YACHTS LIMITED</li>
									<li>SPIANADA SHIP MANAGEMENT  (ΝΑΥΤΙΛΙΑΚΗ)</li>
									<li>SSH MARITIME MANAGEMENT LTD</li>
									<li>SUPER MARITIME LTD</li>
									<li>TST INTERNATIONAL S.A.</li>
									<li>UNIT MARITIME INC</li>
									<li>VARIETY CRUISES</li>
									<li>WESSEX MARITIME CO</li>
									<li>ΑΙΓΙΑΛΗΣ Ν.Ε</li>
									<li>ΑΙΔΗΨΟΣ Ν.Ε.</li>
									<li>ΑΛΜΕΑ ΝΑΥΤΙΛΙΑΚΗ ΕΤΑΙΡΕΙΑ</li>
									<li>ΑΤΛΑΣ ΕΝΩΜΕΝΑ ΝΑΥΠΗΓΕΙΑ ΠΕΡΑΜΑΤΟΣ</li>
									<li>ΑΦΟΒΟΣ Ν.Ε.Π Α</li>
									<li>ΑΦΡΟΜΑΡΙΝ ΝΕ </li>
									<li>ΕΛ & ΕΥΑΓ ΠΑΠΙΛΑΣ & ΣΙΑ ΟΕ (ΝΑΥΠΗΓΕΙΟ)</li>
									<li>ΖΕΦΥΡΟΣ Ν.Ε. (SEKAVIN)</li>
									<li>ΖΩΗ ΓΙΩΤΙΝΓΚ Ν.Ε.Π.Α.</li>
									<li>ΙΝΤΕΡΝΑΥΤΙΚΗ ΑΕΒΕ</li>
									<li>ΙΟΝΙΕΣ ΓΡΑΜΜΕΣ (KERKYRA LINES)</li>
									<li>ΚΙΣΑΜΟΣ ΚΡΟΥΑΖΙΕΡΕΣ Ν.Ε.</li>
									<li>ΕΘΝΙΚΕΣ ΘΑΛΑΣΣΙΕΣ ΥΠΗΡΕΣΙΕΣ ΝΕ</li>
									<li>ΜΑΛΙΜΠΟΥ ΝΕΠΑ</li>
									<li>ΜΕΤΑΛΛΟΒΙΟΤΕΧΝΙΚΗ ΑΒΕΕ</li>
									<li>ΝΑΥΕΠ ΕΠΕ</li>
									<li>ΕΠΙΣΚΕΥΕΣ ΠΛΟΙΩΝ</li>
									<li>ΝΗΡΕΥΣ ΑΕ (ΛΑΙΜΟΣ)</li>
									<li>ΠΕΡΛΕΣ Ν.Ε.Π.Α.</li>
									<li>ΕΚΜ/ΣΗ Ε/Γ - Τ/Ρ ΣΚΑΦΟΥΣ</li>
									<li>(ATALANTA MARINE)</li>
									<li>ΣΕΡΕΣ ΝΑΥΤΙΛΙΑΚΑΙ ΕΠΙΧΕΙΡΗΣΕΙΣ Α.Ε.</li>
									<li>ΝΑΥΠΗΓΕΙΑ ΧΑΛΚΙΔΑΣ  </li>
									<li>ΝΑΥΠΗΓΕΙΑ ΨΑΡΡΟΥ </li>
									<li>ΝΑΥΠΗΓΟΕΠΙΣΚΕΥΑΣΤΙΚΗ ΖΩΝΗ ΠΕΡΑΜΑΤΟΣ</li>
									<li>ΝΑΥΠΗΓΕΙΑ ΝΕΩΡΙΟΝ ΣΥΡΟΥ</li>
								</ol>
							</article>
							</CMPPlainHTML>
						</section>
					</CMPContent>

					{/* <CMPContent className="sidebar">
						<aside className="image">
							<figure>
								<img src="/images/clients/maritime.jpg" alt="" title="" />
							</figure>
							<figure>
								<img src="/images/clients/maritime.jpg" alt="" title="" />
							</figure>
							<figure>
								<img src="/images/clients/maritime.jpg" alt="" title="" />
							</figure>
							<figure>
								<img src="/images/clients/maritime.jpg" alt="" title="" />
							</figure>
							<figure>
								<img src="/images/clients/maritime.jpg" alt="" title="" />
							</figure>
							<figure>
								<img src="/images/clients/maritime.jpg" alt="" title="" />
							</figure>
							<figure>
								<img src="/images/clients/maritime.jpg" alt="" title="" />
							</figure>
							<figure>
								<img src="/images/clients/maritime.jpg" alt="" title="" />
							</figure>
							<figure>
								<img src="/images/clients/maritime.jpg" alt="" title="" />
							</figure>
							<figure>
								<img src="/images/clients/maritime.jpg" alt="" title="" />
							</figure>
						</aside>
					</CMPContent> */}

				</BSNMain>

				<BSNFooter />

			</div>
		);
	}
}

export default Clients;
