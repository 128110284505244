import React from 'react';
import Lightbox from 'react-image-lightbox';
import Translate from 'counterpart';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import 'react-image-lightbox/style.css';
import "./../stylesheets/components/cmp-gallery.sass";

class CMPGalleryItem extends React.Component {

	render() {
		return (
			<div className="cmp-gallery-item" onClick={this.props.openLightbox}>
				<img className="cmp-gallery-item__image" src={this.props.thumb} alt="gallery_item" title="gallery item" />
				<div className="cmp-gallery-item__overlay">
					<FontAwesomeIcon className="cmp-gallery-item__icon" icon={faSearch} fixedWidth={true} />
				</div>
			</div>
		);
	}
};

class CMPGallery extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			gallery: null, 
			activeFilter: null,
			photoIndex: 0,
			isOpen: false,
		};

		this.updateGallery = this.updateGallery.bind(this);
	}

	updateGallery(e, { code } = {}) {

		this.setState({ 
			gallery: code === "all"
				? this.props.images
				: this.props.images.filter(item => item.filters.includes(code)), 
			activeFilter: code,
			photoIndex: 0
		});
	}

	render() {

		const { filters } = this.props;
		const { gallery, activeFilter, photoIndex, isOpen } = this.state;

		return (
			<div className="cmp-gallery">

				{filters && filters.length > 0 
					? 
						<div className="cmp-gallery__filters">
							{filters.map((item, index) => 
								<span 
									className={`cmp-gallery__filter ${activeFilter === item.code ? 'cmp-gallery__filter--active': null}`} 
									key={index} 
									onClick={(e) => this.updateGallery(e, item)}>
									{item.title}
								</span>
							)}
						</div>						
					: null
				}	

				{gallery && gallery.length > 0
					? <div className="cmp-gallery__images">
						{gallery.map((image, index) => 
							<CMPGalleryItem key={index} openLightbox={() => this.setState({ isOpen: true, photoIndex: index })} thumb={image.thumb} />
						)}
						</div>
					: <span className="cmp-gallery__images cmp-gallery__images--empty-state">{Translate("cmpGallery.emptyState")}</span>
				}

				{isOpen && (
					<Lightbox
					mainSrc={gallery[photoIndex].src}
					nextSrc={gallery[(photoIndex + 1) % gallery.length].src}
					prevSrc={gallery[(photoIndex + gallery.length - 1) % gallery.length].src}
					onCloseRequest={() => this.setState({ isOpen: false })}
					animationOnKeyInput={true}
					imageCaption={gallery[photoIndex].caption}
					onMovePrevRequest={() =>
						this.setState({
							photoIndex: (photoIndex + gallery.length - 1) % gallery.length,
						})
					}
					onMoveNextRequest={() =>
						this.setState({
							photoIndex: (photoIndex + 1) % gallery.length,
						})
					}
					/>
				)}
			</div>
		);
	}

	componentDidMount() {
		this.setState({ gallery: this.props.images });

		if (this.props.filters) {
			this.setState({ activeFilter: this.props.filters[0].code });
		}
	}
}

export default CMPGallery;
