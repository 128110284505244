import Translate from 'counterpart';

const rules = {
	isFilled: (value) => {
		if (!value) {
			return { isValid: false };
		}
		return { isValid: true };
	},
	isEmail: (value) => { // eslint-disable-next-line 
		const emailRegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		if (!emailRegExp.test(String(value).toLowerCase())) {
			return { isValid: false };
		}
		return { isValid: true };
	},
	isMoreThan: (value, threshold) => {
		if (value > threshold) {
			return { isValid: false };
		}
		return { isValid: true };
	},
	isLessThan: (value, threshold) => {
		if (value < threshold) {
			return { isValid: false };
		}
		return { isValid: true };
	},
	isExact: (value, threshold) => {
		if (value !== threshold) {
			return { isValid: false };
		}
		return { isValid: true };
	},
};

const validateField = (value, validations = []) => {

	const errors = [];
	
	for (let rule of validations) {

		switch (rule.code) {
			case "checked":
				if (!rules.isFilled(value).isValid) {
					errors.push({ code: rule.code, message: rule.message || Translate('forms.generic.validation.checked') });
				}
				break;
			case "required":
				if (!rules.isFilled(value).isValid) {
					errors.push({ code: rule.code, message: rule.message || Translate('forms.generic.validation.required') });
				}
				break;
			case "email":
				if (!rules.isEmail(value).isValid) {
					errors.push({ code: rule.code, message: rule.message || Translate('forms.generic.validation.email') });
				}
				break;
			case "max-chars":
				if (!rules.isMoreThan(value.length, rule.value).isValid) {
					errors.push({ code: rule.code, message: rule.message || `${Translate('forms.generic.validation.maxChars')} ${rule.value} ${Translate('forms.generic.validation.chars')}` });
				}
				break;
			case "min-chars":
				if (!rules.isLessThan(value.length, rule.value).isValid) {
					errors.push({ code: rule.code, message: rule.message || `${Translate('forms.generic.validation.minChars')} ${rule.value} ${Translate('forms.generic.validation.chars')}` });
				}
				break;
			case "exact-chars":
				if (!rules.isEmail(value.length, rule.value).isValid) {
					errors.push({ code: rule.code, message: rule.message || `${Translate('forms.generic.validation.exactChars')} ${rule.value} ${Translate('forms.generic.validation.chars')}` });
				}
				break;
			default:
				break;
		}
	}


	return { isValid: errors.length <= 0, errors };
};

export {
	validateField as ValidateField
};
