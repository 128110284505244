import React from 'react';
import Translate from 'counterpart';
import Markdown from 'react-markdown';
import { Keys, GetStoredObject } from './../helpers/storage';
import CMPPlainHMTL from './../components/cmp-plain-html';
import Config from './../config/';
import TermsEL from './../lang/terms_el.md';
import TermsEN from './../lang/terms_en.md';
import "./../stylesheets/pages/terms.sass";

class Terms extends React.Component {

	constructor(props) {
		super(props);

		this.state = { markdownEL: '', markdownEN: '' };
	}

	replaceTextWithTranslations(text) {
		
		const start = "{{";
		const end = "}}";

		let startindex = text.indexOf(start);
		let endindex = text.indexOf(end, startindex);

		while (startindex !== -1 && endindex !== -1 &&  endindex  > startindex ) {
			
			const term = text.substring(startindex + start.length , endindex);
			text = text.replace(`{{${term}}}`, Translate(term));
			
			startindex = text.indexOf(start);
			endindex = text.indexOf(end, startindex);
		}

		return text;
	}

	replaceTextWithConfig(text) {
		const start = "{%";
		const end = "%}";

		let startindex = text.indexOf(start);
		let endindex = text.indexOf(end, startindex);

		while (startindex !== -1 && endindex !== -1 &&  endindex  > startindex ) {
			
			const term = text.substring(startindex + start.length , endindex);
			const termParts = term.split('.');

			if (termParts.length === 2) {
				text = text.replace(`{%${term}%}`, Config[termParts[0]][termParts[1]]);
			}
			else { // (termParts.length === 1)
				text = text.replace(`{%${term}%}`, Config[term]);
			}
			
			startindex = text.indexOf(start);
			endindex = text.indexOf(end, startindex);
		}

		return text;
	}

	render() {

		const { markdownEL, markdownEN } = this.state;

		return (
			<div id="terms">

				<CMPPlainHMTL>
					<Markdown source={GetStoredObject(Keys.CURRENT_LANG) === "el" ? markdownEL : markdownEN} />
				</CMPPlainHMTL>

			</div>
		);
	}

	async componentWillMount() {
		
		const termsEl = await fetch(TermsEL).then(res => res.text());
		let replacedTermsEl = this.replaceTextWithTranslations(termsEl);
		replacedTermsEl = this.replaceTextWithConfig(replacedTermsEl);
		this.setState({ markdownEL: replacedTermsEl });
		
		const termsEn = await fetch(TermsEN).then(res => res.text());
		let replacedTermsEn = this.replaceTextWithTranslations(termsEn);
		replacedTermsEn = this.replaceTextWithConfig(replacedTermsEn);
		this.setState({ markdownEN: replacedTermsEn });
	}
}

export default Terms;
