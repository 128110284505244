import React from 'react';
import "./../stylesheets/components/cmp-logo.sass"

class CMPLogo extends React.Component {


	render() {
		return (
			<a className="cmp-logo" href="/" title="Home">
				{this.props.imagePath ? (
					<img className="cmp-logo__image" src={this.props.imagePath} alt={this.props.shortTitle} title={this.props.title} />
				) : (
					<span title={this.props.title}>{this.props.shortTitle}</span>
				)}
			</a>
		);
	}
}

export default CMPLogo;
