import React from 'react';
import Config from './../config';
import Package from './../../package.json';
import "./../stylesheets/components/cmp-version.sass"

class CMPVersion extends React.Component {

	render() {

		return (
			Config.environment !== "production" 
				?
					<pre className="cmp-version">
						<code>v:<strong>{Package.version}</strong> &ndash; env:<strong>{Config.environment}</strong></code>
					</pre>
				: <span style={ { display : 'none' } }>
					{`v.${Package.version} / env:${Config.environment}`}
				</span>
		);
	}
}

export default CMPVersion;
