import React from 'react';
import Translate from 'react-translate-component';
import CMPHeader from './../components/cmp-header';
import CMPContent from './../components/cmp-content';
import BSNMain from './../components/business/bsn-main';
import BSNNavigationPanel from './../components/business/bsn-navigation-panel';
import BSNHeader from './../components/business/bsn-header';
import BSNFooter from './../components/business/bsn-footer';

import "./../stylesheets/pages/about.sass";

class About extends React.Component {

	render() {

		return (
			<div id="about">

				<BSNHeader>
					<BSNNavigationPanel language={this.props.lang} active="about" />
					<CMPHeader title={<Translate content="menu.about" />} />
				</BSNHeader>

				<BSNMain>
					<CMPContent className="content">
						<section>
							<header>
								<Translate content="pages.about.section.one.title" component="h2" />
							</header>
							{/* <Translate content="pages.about.section.one.description" component="p" /> */}
							<figure>
								<img src="/images/fleet.jpg" alt="fleet.jpg" title="Company Headquarters" />
								<Translate content="pages.about.section.one.caption" component="figcaption" />
							</figure>
						</section>
						{/* <section>
							<header>
								<Translate content="pages.about.section.two.title" component="h2" />
							</header>
							<Translate content="pages.about.section.two.description" component="p" />
						</section> */}
					</CMPContent>

				</BSNMain>

				<BSNFooter />

			</div>
		);
	}
}

export default About;
