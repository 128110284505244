import API from './api';
import { Reply, Tools } from './helpers';
import { Keys, CheckStoredObject, GetStoredObject, StoreObject } from './storage';

const createUser = async ({ Guid: guid }) => {
	// Make call to the API - Record new user to DB
	const endpoint = API.endpoints.users.create();
	return await API.request(endpoint, { id: guid }).then(API.response);
};

const sendAuthorizationRequest = async ({ Guid: guid }) => {
	const endpoint = API.endpoints.users.authorize();
	return await API.request(endpoint, { user: { id: guid }}).then(API.response);
};

const validations = {
	isEmpty: ({ Value: value }) => !value,
	isGUID: ({ Guid: guid }) => Tools.GUID.Check(guid),
	isNotEmpty: ({ Value: value }) => value ? true : false,
	isUserTokenActive: ({ TokenExpiration: tokenExpiration }) => {
		const now = new Date();
		const tokenExpirationDate = new Date(tokenExpiration);
		if (tokenExpirationDate.getTime() - now.getTime() > 0)
			return true;
		else
			return false;
	}
};

const authorize = async () => {
	const userIdExists = CheckStoredObject(Keys.USER_ID);
	const userTokenExists = CheckStoredObject(Keys.USER_TOKEN);
	const userTokenExpExists = CheckStoredObject(Keys.USER_TOKEN_EXPIRATION);

	// ===========================
	// SCENARIO 1 - RETURNING USER
	// ===========================
	if (userIdExists && userTokenExists && userTokenExpExists) {
		const isUserIdValueValid = validations.isGUID({ Guid: GetStoredObject(Keys.USER_ID) });
		const isUserTokenValueValid = validations.isNotEmpty({ Value: GetStoredObject(Keys.USER_TOKEN) });
		const isUserTokenExpirationValueValid = validations.isUserTokenActive({ TokenExpiration: GetStoredObject(Keys.USER_TOKEN_EXPIRATION) });

		if (isUserIdValueValid && isUserTokenValueValid && isUserTokenExpirationValueValid) {
			// =========================
			// CASE 1 - AUTHORIZED -> OK
			// =========================
			return Reply.basic({ success: true });
		}
		else if (isUserIdValueValid) {
			// ===============================================
			// CASE 2 - TOKEN EXPIRED / TOKEN INVALID -> Renew
			// ===============================================
			const response = await sendAuthorizationRequest({ Guid: GetStoredObject(Keys.USER_ID) });
			if (!response.success) return Reply.basic({ errors: response.errors, message: response.message });

			StoreObject(Keys.USER_TOKEN, response.data.token, { force: true });
			StoreObject(Keys.USER_TOKEN_EXPIRATION, response.data.exp, { force: true });

			return Reply.basic({ success: true });
		}
		else {
			// =====================================================
			// CASE 3 - NEW USER / CORRUPTED USER -> Verify & Create
			// =====================================================
			const guid = Tools.GUID.Generate();
			const response = await sendAuthorizationRequest({ Guid: guid });
			if (!response.success) return Reply.basic({ errors: response.errors, message: response.message });

			StoreObject(Keys.USER_ID, guid, { force: true });
			StoreObject(Keys.USER_TOKEN, response.data.token, { force: true });
			StoreObject(Keys.USER_TOKEN_EXPIRATION, response.data.exp, { force: true });

			await createUser({ Guid: guid });

			return Reply.basic({ success: true });
		}
	}
	// =====================
	// SCENARIO 2 - NEW USER
	// =====================
	else {
		const guid = Tools.GUID.Generate();
		const { data, errors, message, success } = await sendAuthorizationRequest({ Guid: guid });
		if (!success) return Reply.basic({ errors, message });

		StoreObject(Keys.USER_ID, guid, { force: true });
		StoreObject(Keys.USER_TOKEN, data.token, { force: true });
		StoreObject(Keys.USER_TOKEN_EXPIRATION, data.exp, { force: true });

		await createUser({ Guid: guid });
		
		return Reply.basic({ success: true });
	}
};

const security = {
	authorize: authorize
};

export default security;
