import React from 'react';
import { Link } from 'react-router-dom';
import Translate from 'counterpart';
import Config from './../config';
import API from './../helpers/api';
import MDLLoader from './../modules/mdl-loader';
import CMPLogo from './../components/cmp-logo';
import E404 from './404';
import CMPHeader from './../components/cmp-header';
import CMPContent from './../components/cmp-content';
import CMPPlainHTML from './../components/cmp-plain-html';
import CMPCopyright from './../components/cmp-copyright';
import CMPFooter from './../components/cmp-footer';

import "./../stylesheets/pages/email-message.sass";

class EmailMessage extends React.Component {

	constructor(props) {
		super(props);
		this.state = { data: {} };
		this.fetchEmailMessage = this.fetchEmailMessage.bind(this);
	}

	async fetchEmailMessage() {

		const { match: { params } } = this.props;
		
		const loader = new MDLLoader({ selector: '#email-message' });
		loader.Start();

		// Make call to the API
		const endpoint = API.endpoints.messages.get(params.id);
		const response = await API.request(endpoint).then(API.response);
		this.setState({ data: response.data });
		
		loader.Stop();
	}

	render() {

		const { data } = this.state;

		if (!data) {
			return <E404 />
		}

		return (
			<div id="email-message">
				<CMPHeader>
					<CMPLogo imagePath="images/logo.png" />
					<h2 className="email-message__title">{Translate('pages.emailMessage.moto')}</h2>
				</CMPHeader>

				<CMPContent>
					<CMPPlainHTML>

						<section>
							<h2>{Translate('pages.emailMessage.thankYouTitle')}</h2>
							<p>{Translate('pages.emailMessage.thankYouMessage')}</p>
						</section>

						<section>
							<h2>{Translate('pages.emailMessage.messageSent')}</h2>
							<p><em>{data.message}</em></p>
						</section>

						<section>
							<h2>{Translate('pages.emailMessage.yourContactDetails')}</h2>
							<ul>
								<li><strong>{Translate('pages.emailMessage.name')}:</strong> {data.name}</li>
								<li><strong>{Translate('pages.emailMessage.phone')}:</strong> {data.phone}</li>
								<li><strong>{Translate('pages.emailMessage.email')}:</strong> {data.email}</li>
							</ul>
						</section>

						<hr />

						<ul className="email-message__contact-details">
							<li><Link to="/">{Config.domain}</Link></li>
							<li>&ndash;</li>
							<li><a href={`mailto:${Config.email.contact}`}>{Config.email.contact}</a></li>
						</ul>

						<ul className="email-message__contact-details">
							<li>{Translate('identity.phone1')}</li>
							<li>&ndash;</li>
							<li>{Translate('identity.phone2')}</li>
						</ul>

						<ul className="email-message__contact-details">
							<li>{Translate('identity.address')} {Translate('identity.postalCode')}, {Translate('identity.country')}</li>
						</ul>
					</CMPPlainHTML>
				</CMPContent>

				<CMPFooter>
					<CMPCopyright />
				</CMPFooter>
			</div>
		);
	}

	componentDidMount() {
		this.fetchEmailMessage();
	}
}

export default EmailMessage;
