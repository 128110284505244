import local from './local';
import development from './development';
import production from './production';
import staging from './staging';

let config;

switch(process.env.REACT_APP_ENVIRONMENT) {
	case 'local':
		config = local;
		break;
	case 'development':
		config = development;
		break;
	case 'staging':
		config = staging;
		break;
	case 'production':
		config = production;
		break;
	default:
		config = development
		break;
}

export default config;
