import React from 'react';
import ee from 'event-emitter';
import { HashRouter, Route, Switch } from "react-router-dom";
import Counterpart from 'counterpart';
import Responsive from './../helpers/responsive';
import { Keys, GetStoredObject, StoreObject } from './../helpers/storage';
import MDCSnackbar from './../components/material/mdc-snackbar';
import CMPOverlay from './../components/cmp-overlay';
import CMPVersion from './../components/cmp-version';
import Home from './home';
import Services from './services';
import Clients from './clients';
import Gallery from './gallery';
import About from './about';
import EmailMessage from './email-message';
import Contact from './contact';
import Unsubscribe from './unsubscribe';
import E404 from './404';

import el from './../lang/el';
import en from './../lang/en';

import "./../stylesheets/app.sass";

const emitter = new ee();

Counterpart.registerTranslations('en', en);
Counterpart.registerTranslations('el', el);

const langFromCache = GetStoredObject(Keys.CURRENT_LANG) || 'el';
Counterpart.setLocale(langFromCache);

export const ChangeLanguage = (event) => {
	emitter.emit('App:ChangeLanguage', event);
}


export class App extends React.Component {

	constructor(props) {
		super(props);
		this.device = new Responsive();
		this.state = { language: langFromCache };
		this.changeLanguage = this.changeLanguage.bind(this);
		emitter.on('App:ChangeLanguage', this.changeLanguage);
	}

	changeLanguage(lang) {
		StoreObject(Keys.CURRENT_LANG, lang, { force: true });
		Counterpart.setLocale(lang);
		this.setState({ language: lang });
	}

	render () {
		return (
			<div className="App">
				
				<CMPOverlay />
				<MDCSnackbar />
				<CMPVersion />

				<HashRouter>
					<Switch>
						<Route exact path="/" render={props => <Home {...props} lang={this.state.language} />} />
						<Route exact path="/services" render={props => <Services {...props} lang={this.state.language} />} />
						<Route exact path="/clients" render={props => <Clients {...props} lang={this.state.language} />} />
						<Route exact path="/gallery" render={props => <Gallery {...props} lang={this.state.language} />} />
						<Route exact path="/about" render={props => <About {...props} lang={this.state.language} />} />
						<Route exact path="/contact" render={props => <Contact {...props} lang={this.state.language} />} />
						<Route exact path="/message/:id" render={props => <EmailMessage {...props} lang={this.state.language} />} />
						<Route exact path="/unsubscribe" render={props => <Unsubscribe {...props} lang={this.state.language} />} />
						<Route component={E404} />
					</Switch>
				</HashRouter>
			</div>
		)
	}
}
