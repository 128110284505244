import React from 'react';
import "./../stylesheets/components/cmp-footer.sass"

class CMPFooter extends React.Component {

	render() {
		return (
			<footer className="cmp-footer">
				{this.props.children}
			</footer>
		);
	}
}

export default CMPFooter;
