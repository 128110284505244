import * as moment from 'moment';

const tools = {
	Convert: {
		ToDate: (dateISO) => moment(dateISO).format('YYYY-MM-DD'),
		toDisplayDate: (dateISO) => moment(dateISO).format('DD/MM/YYYY (ddd)'),
		ToAmount: (value) => {
			if (!value) { return null; }
			const sign = "€";
			return `${value} ${sign}`;
		}
	},
	GUID: {
		Check: (guid) => /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/.test(guid),
		Generate: () => ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c => // eslint-disable-next-line
		(c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)),
	},
	Transform: {
		reorder: (a, b) => {
			const sortByDate = (x, y) => {
				const dateX = moment(x);
				const dateY = moment(y);
				if (dateX < dateY) {
					return -1;
				}
				if (dateX > dateY) {
					return 1;
				}
				return 0;
			};

			const sortByNumber = (x, y) => {
				if (parseFloat(x) < parseFloat(y)) {
					return -1;
				}
				if (parseFloat(x) > parseFloat(y)) {
					return 1;
				}
				return 0;
			};

			const sortByString = (x, y) => {
				if (x < y) {
					return -1;
				}
				if (x > y) {
					return 1;
				}
				return 0;
			};

			if (moment(a, 'YYYY-MM-DDTHH:mm:ss').isValid())
				return sortByDate(a, b);
			else if (!isNaN(a))
				return sortByNumber(a, b);
			else
				return sortByString(a, b);
		},
		transactionsGroupedByDate: (transactions) => {

			const transactionsGroupedByDate = [];

			for (let i = 0; i <= transactions.length; i++) {
				const item = transactions[i];

				const group = {
					date: item.date,
					items: []
				};

				let j = i + 1;
				if (!transactions[j]) break;
				group.items.push(item);
				while (moment(transactions[j].date).isSame(item.date, 'day') && j <= transactions.length - 1) {
					group.items.push(transactions[j]);
					j++;
				}

				transactionsGroupedByDate.push(group);
			}
			return transactionsGroupedByDate
		}
	}
};

const reply = {
	basic: ({ data = [], errors = [], message = '', success = false }) => {
		return {
			data,
			errors,
			message,
			success
		}
	}
};

export {
	reply as Reply,
	tools as Tools
};
